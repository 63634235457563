$protean-gray: #a4a8b1;
$protean-black: #24272a;
$protean-dark-gray: #616674;
$protean-white: #f7f9fa;

:root {
  --protean-gray: #{$protean-gray};
  --protean-dark-gray: #{$protean-dark-gray};
  --protean-black: #{$protean-black};
  --protean-white: #{$protean-white};
}
