//font-size
.font-size-20 {
  font-size: 20px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-24 {
  font-size: 24px;
}

//font-weight
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}

.font-color-grey {
  color: $protean-gray;
}
.font-color-black {
  color: $protean-black;
}
.font-color-white {
  color: $protean-white;
}
.font-color-dark-grey {
  color: $protean-dark-gray;
}
