.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.pb-6px {
  padding-bottom: 6px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mt-12 {
  margin-top: 12px;
}

.mx-7px {
  margin-left: 7px;
  margin-right: 7px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-16 {
  margin-bottom: 16px;
}

.my-16 {
  margin-top: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-44 {
  margin-bottom: 44px;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.mb-16 {
  margin-bottom: 14px !important;
}

.p-8 {
  padding: 8px !important;
}

.pr-8 {
  padding-right: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.pr-16 {
  padding-right: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pr-32 {
  padding-right: 32px;
}

.pl-32 {
  padding-left: 32px;
}

.pt-32 {
  padding-top: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.zi-9 {
  z-index: 9999 !important;
}

.zi-8 {
  z-index: 95;
}
