.background-jobs {
  .stats-block {
    display: flex;
    width: 100%;
    justify-content: space-around;
    .stat {
      .stat-item {
        display: flex;
        width: 20%;
        flex-direction: column;
        justify-content: center;
        h3, h4 {
          text-align: center;
        }
      }
      display: flex;
      width: 45%;
      flex-wrap: wrap;
      margin-right: 32px;
      justify-content: center;
      h2 {
        text-align: center;
        width: 100%;
      }
    }
  }
  /* .atg-content { */
  /*   .stats { */
  /*     h2 { */
  /*       font-size: 20px; */
  /*     } */
  /*   } */
  /* } */
}

.job-modal .m-body {
  height: auto !important;
}
.job-detail-modal {
  display: flex;
  flex-direction: column;
  .devider {
    padding: 0px 32px;
  }
  .job-logs {
    padding: 32px;
    display: flex;
    flex-direction: column;
    .log-block {
      display: flex;
      align-items: center;
      .log-title {
        font-size: 16px;
        font-weight: 500;
        margin-right: 16px;
      }
      .log-value {
        font-size: 16px;

      }
    }
  }
  .job-actions {
    padding: 32px;
        display: flex;

    button {
      width: 150px;
      margin-left: auto;
    }
  }
  .job-info {
    padding: 32px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .info-block {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30%;
      .info-title {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
      }
      .info-value {
        text-align: center;
        font-size: 16px;
      }
    }
  }
}
