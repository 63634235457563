.top-z-index {
  z-index: 9999;
}
.priority-wrapper {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: $protean-white solid 7px;
}
.workorder-block {
  .logs-list {
    max-height: 235px;
    overflow-y: auto;
  }
  .block-title {
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    padding-bottom: 20px;
    cursor: pointer;
  }
}

.group-modal {
  width: 965px;
  max-width: 965px;
  .unselected-issues {
    max-height: 320px;
    overflow-y: auto;
  }
  .issue-wrap {
    max-width: 900px;
    max-height: 100px;
    overflow-y: auto;
    width: fit-content;
    flex-wrap: wrap;
  }
  .separator {
    height: 5px;
    background-color: $protean-white;
  }
  &.update-modal {
    .wrapper {
      max-height: 724px;
      overflow-y: auto;
    }
    .created-label {
      font-family: Roboto;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
      span {
        font-family: Roboto;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }
    }
  }
  &.delete-modal {
    width: 685px;
    .delete-text {
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
    }
    .delete-note {
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
    }
  }

  .issue-tag {
    width: fit-content;
    margin: 0 12px 12px 0;
    padding: 4px 10px;
    border-radius: 15px;
    background-color: #e0e8f1;
    color: #3a4669;
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;
  }
  .command-header h2 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
  }
  .body {
    padding-top: 20px;
    .group-modal-buttons {
      place-content: flex-end;
      .protean-btn {
        width: 120px;
        text-align: center;
        justify-content: center;
      }
    }
    .wo-tbody {
      .wo-tr {
        background-color: #ffffff;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
        border-bottom: $protean-white solid 1px;
        &.selected {
          background-color: #ffecec;
        }
      }
    }
    .comment-textarea {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      width: 100%;
      border-radius: 4px;
      border: solid 1px #eaeeef;
      background-color: #ffffff;
      padding: 16px 12px;
      resize: none;
    }
  }
  .label {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-gray;
    padding-bottom: 8px;
  }
}
.sub-table {
  .wo-subtable-row {
    border-bottom: 1px solid #f7f9fa;
  }
}

.protean-workorder {
  .site-address {
    color: $protean-gray;
  }
  .block-title {
    .extra {
      justify-content: flex-end;
      margin-top: -10px;
    }
  }
  .comment-group {
    .comment {
      .title {
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
      }
    }
    .inner-comment {
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $protean-dark-gray;
      font-size: 14px;
      font-weight: normal;
      .title {
        font-size: 14px;
        font-weight: bold;
      }
      .date {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.5;
      }
    }
  }
  .actions-cell {
    .icon {
      cursor: pointer;
      &.disabled {
        cursor: default;
      }
    }
    svg {
      flex: 1 1 32px;
    }
    svg.delete-icon {
      g {
        fill: #d96161;
      }
      &.disabled {
        g {
          fill: $protean-gray;
        }
      }
    }
    svg.edit-icon {
      g {
        fill: $protean-dark-gray;
      }
      &.disabled {
        g {
          fill: $protean-gray;
        }
      }
    }
  }

  .workorder-block-title {
    margin-bottom: 44px;
    .actions {
      .dropdown-menu {
        right: 0;
        left: unset;
        width: 534px;
      }
      div > div > #keys-map {
        &.keys-map {
          //height: 220px;
          //padding: 24px;
          padding: 8px 16px 0 16px;
          border-radius: 4px;
          background-color: $protean-white;
          display: flex;
          flex-direction: row;
          .column {
            width: 50%;
            .keys-item {
              flex: 0.5;
              display: flex;
              margin-bottom: 8px;
              height: 22px;
              .keys-title {
                height: 22px;
                min-width: 90px;
                border-radius: 4px;
                background-color: #ffffff;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $protean-dark-gray;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.true {
                  background-color: transparent !important;
                }
              }
              .keys-value {
                height: 22px;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-dark-gray;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
      .protean-btn {
        height: 40px;
        width: 40px;
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;
      }
    }

    .wo-title {
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
    }
    .site-name {
      margin-top: 24px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-gray;
    }
  }
}

.wo-tracking {
  &.on-cleared {
    box-shadow: 0 0 0 0 rgba(87, 142, 247, 0.3);
    background-color: #f7f9fa;
    animation: pulse 2s infinite linear;
    border: solid 1px #578ef7;
  }
}
.filter-cleared-info {
  margin-left: 10px;
  width: 267px;
  color: $protean-dark-gray;
  animation: blink-animation 2s steps(5, start) 1;
  -webkit-animation: blink-animation 2s steps(5, start) 1;
}

@keyframes blink-animation {
  to {
    color: #578ef7;
  }
}
@-webkit-keyframes blink-animation {
  to {
    color: #578ef7;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(87, 142, 247, 0.3);
  }
  70% {
    -webkit-box-shadow: 0 2px 14px 0 rgba(87, 142, 247, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(87, 142, 247, 0.3);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(87, 142, 247, 0.3);
    box-shadow: 0 0 0 0 rgba(87, 142, 247, 0.3);
  }
  70% {
    -moz-box-shadow: 0 2px 14px 0 rgba(87, 142, 247, 0.3);
    box-shadow: 0 2px 14px 0 rgba(87, 142, 247, 0.3);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(87, 142, 247, 0.3);
    box-shadow: 0 0 0 0 rgba(87, 142, 247, 0.3);
  }
}

.protean-tooltip.wo-group-name {
  max-width: 274px;
  line-height: 1.38;
}
