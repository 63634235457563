.page-header-new {
  .breadcrumb-line {
    background-color: #fefefe;
  }

  .support-btn {
    font-size: 13px;
    font-weight: 500;
    color: #616674;
  }

  &.no-extra {
    border-bottom: solid 1px #eaeeef;
    margin-bottom: 33px;
  }
}

.octo {
  //display: none;
  position: fixed;
  bottom: -400px;
  right: 0;
  transition: all 0.5s;

  &.show {
    display: block;
    transform: translateY(-164px);
    transition: all 1s;

    .move {
      .left-eye {
        transform: translateX(-12px);
        transition: all 0.5s;
      }

      .right-eye {
        transform: translateX(-12px);
        transition: all 0.5s;
      }
    }
  }

  .left-eye {
    transform: translateX(-5px);
    transition: all 0.5s;
  }

  .right-eye {
    transform: translateX(-5px);
    transition: all 0.5s;
  }
}

.setup-command-filter {
  position: relative;
  width: 330px;
  margin-left: 16px;

  input {
    height: 40px !important;
  }

  .search-icon {
    position: absolute;
    top: 8px;
    right: 16px;

    .search-icon-path {
      fill: #a4a8b1;
    }
  }
}

.protean-atg-commands.table-content .setup-commands-table {
  &.wo-table > .wo-thead > .wo-tr > .wo-th {
    color: #616674;
  }
}

.breadcrumb-item.new {
  font-size: 13px;
  font-weight: 500;
  color: #616674;
  display: flex;

  &.active {
    font-size: 13px;
    font-weight: 500;
    color: #a4a8b1;
  }

  svg {
    margin-right: 4px;
    height: 18px;
    width: 18px;
  }
}

.alarm-table-row-options {
  .protean-btn {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.atg-links {
  &.setup {
    display: inline-flex !important;
    margin-left: auto;

    button.reportBtn {
      height: 32px !important;
      width: 44px !important;
    }
  }

  &.command-center {
    button.reportBtn {
      padding: 1px !important;
      height: 30px !important;
      width: 30px !important;
    }
  }

  &.release_detection {
    button.reportBtn {
      height: 32px !important;
      width: 32px !important;
    }
  }

  &.conCenter {
    button.reportBtn {
      &:hover {
        background-color: transparent !important;
      }

      background-color: transparent !important;
      // padding: 0.4375rem 0.875rem !important;
      height: 32px !important;
      width: 32px !important;
    }
  }

  &.alarmsDrop {
    button.reportBtn {
      /* background-color: transparent !important; */
      height: 32px !important;
      width: 43px !important;
    }
  }

  .dropdown-menu {
    max-height: unset !important;

    .dropdown-item {
      svg {
        margin-right: 8px;
      }
    }
  }
}

// SingleDatePickerFIX
.date-custom-styles {
  .SingleDatePickerInput__withBorder {
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: hsl(0, 0%, 80%);
  }

  .DateInput.DateInput_1 {
    width: 100%;
    height: 38px;
    border-radius: 4px;

    .DateInput_input {
      height: 38px !important;
      font-size: 13px;
      color: hsl(0, 0%, 50%);
      font-weight: 400;
      border-radius: 4px;
    }
  }

  .CalendarMonth_caption.CalendarMonth_caption_1 {
    width: 277px;
  }

  div {
    width: 100%;
  }

  .DateInput_input__focused {
    border: none;
  }

  .SingleDatePicker_picker {
    width: auto;

    .DayPicker {
      width: auto;

      .DayPickerNavigation_button {
        width: auto;
      }
    }
  }
}

// custom header logo class
.custom-header-logo {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 20px;
  min-width: 100px !important;

  img {
    height: 2rem;
  }
}

.settings-button {
  &:hover {
    box-shadow: none !important;
    opacity: 0.75;
  }

  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

.generate-modal {
  .generate-loading {
    margin-top: 35%;

    & > div:first-of-type {
      margin-top: -48px;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
      text-align: center;
    }
  }
}

.Toastify__toast-container--top-right {
  top: 4em;
  right: 4em;

  .Toastify__toast {
    padding: 24px;
    border-radius: 4px;
    width: 360px;
    // height: 142px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #eaeeef;
    background-color: #ffffff;

    .Toastify__toast-body {
      font-size: 15px;
      color: $protean-black;
      margin: 0;

      .content {
        padding: 0;

        .title {
          margin-bottom: 16px;
          font-size: 16px;
          font-weight: bold;
          color: $protean-black;
        }

        .text {
          font-size: 15px;
          color: $protean-black;
          margin-top: 10px;
        }
      }
    }

    .Toastify__close-button {
      color: $protean-dark-gray;
    }
  }
}

@keyframes zoomIn {
  from {
    opacity: 1;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

.list-group-item.list-group-item-action {
  word-break: break-all;
}

.testTableWrapper {
  border: 1px solid rgba(0, 0, 0, 0.07);

  .card-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07) !important;

    .c-title {
      color: rgb(91, 91, 96);
      font-size: 16px;
    }
  }
}

.progress-bar-protean {
  background-color: #bcd87a !important;
}

.header-link {
  color: #333;

  &:hover {
    color: #333;
    opacity: 0.75;
  }
}

.nav-item.custom-nav {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-dark-gray;

  svg {
    height: 24px;
  }

  .nav-link {
    align-content: center;

    span {
      margin-left: 20px;
      display: flex;
      align-self: center;
    }
  }
}

@media (min-width: 768px) {
  .sidebar:not(.sidebar-component) {
    position: static !important;
  }
}

@media (max-width: 1px) {
  #mobile-hide {
    display: none;
  }

  .protean-navbar {
    height: unset !important;
  }

  /* .content-wrapper { */
  /*   padding-left: 1.25rem !important; */
  /* } */
}

/* .sidebar:not(.sidebar-component) { */
/*   // fix for side bar */
/*   position: fixed; */
/* } */

.nav-item-submenu.custom-submenu {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-dark-gray;

  svg {
    height: 24px;
  }

  .nav-link {
    &:after {
      top: 15px;
    }

    display: flex;

    span {
      margin-left: 20px;
      align-self: center;
      display: flex;
    }
  }
}

.facility-page {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .facility-breadcrumbs {
    @media screen and (max-width: 1280px) {
      .breadcrumb {
        padding: 8px 13px;
        height: 44px !important;
      }
    }

    border-bottom: 5px solid $protean-white;
    padding: 9px 8px 0 8px;

    .breadcrumb {
      flex: 0.9 1;
      height: 31px;
      margin: 0 4px;
      border-radius: 4px;
      background-color: $protean-white;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;

      &:hover {
        background-color: #f0f2f4;
      }
    }
  }

  .card-devider {
    border-top: 5px solid $protean-white !important;
  }

  // #Issues {
  //   min-height: 532px;
  // }
  .facility-header {
    align-items: end;

    .facility-actions {
      align-items: flex-start;
      align-self: flex-start;

      .facility-action-btn {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        background-color: $protean-white;
        border: none;

        &:hover {
          filter: invert(10%);
        }
      }
    }
  }

  .facility-title {
    font-family: Roboto;
    color: $protean-black;
  }

  .facility-tab-title {
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
  }

  .facility-address {
    font-size: 19px;
    color: $protean-dark-gray;
  }

  .facility-tab {
    font-size: 13px;
    font-weight: 500;
    color: $protean-gray;

    &.active {
      color: #283049;

      &:before {
        background-color: #bcd87a;
      }
    }
  }

  .facility-item-header {
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;

    min-width: 128px;
    height: 30px;
    border-radius: 4px;
    background-color: $protean-white;
    margin-right: 2px;

    &.long-header {
      min-width: 60% !important;
    }
  }

  .facility-item-value {
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    width: 100%;
    border-radius: 4px;
    background-color: $protean-white;
    margin-right: 20px;
  }

  .Collapsible {
    margin: 32px 0;
    padding: 0 18px;

    .Collapsible__contentInner {
      margin-top: 31px;
    }

    .block-title {
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }
    }

    .wo-thead {
      .wo-tr {
        .wo-th {
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
          position: relative;
        }

        .date-filter {
          display: none;

          &.date {
            display: block;

            .picker {
              position: absolute;
              top: 5px;
              left: 7px;
              display: none;
              z-index: 9;

              .close-picker {
                display: none;

                &.show {
                  display: block;
                  position: absolute;
                  top: 5px;
                  right: 10px;
                }
              }

              &.true {
                display: block;

                .datepicker-block {
                  .icon {
                    display: none;
                  }

                  input {
                    width: 98%;
                    height: 26px;
                    padding-left: 6px;
                    // width: inherit;
                    // height: inherit;
                  }

                  .custom-picker {
                    top: 25px;
                  }
                }
              }

              .sortNone {
                display: block;
                position: absolute;
                top: 5px;
                right: 28px;
              }

              .sortUp {
                display: block;
                position: absolute;
                top: 5px;
                right: 28px;
                margin-bottom: 0 !important;
              }

              .sortDown {
                display: block;
                position: absolute;
                top: 5px;
                right: 28px;
                margin-top: 0 !important;
              }
            }
          }
        }

        .filter-select.date {
          display: none;
        }

        .hide {
          display: none;
        }
      }
    }

    .extra {
      &.extra-setup {
        height: unset;
        margin-bottom: 0;
      }

      height: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      justify-content: flex-end;

      .add-test {
        margin-right: auto;
      }

      .pagination-size {
        margin-left: 50px;
      }

      .protean-btn {
        &:disabled {
          opacity: 0.7;
          color: unset;
        }

        &.pass,
        &.schedule {
          color: #fff;
          width: 120px;
          background-color: #578ef7;
        }

        &.fail {
          width: 120px;
          color: #fff;
          background-color: #d96161;
        }

        &.upload {
          display: flex;
          align-items: center;

          svg {
            g {
              path {
                fill: #3a4669;
              }
            }
          }
        }
      }

      .table-title {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }
    }

    .wo-tr {
      border-radius: 4px;
      background-color: $protean-white;
      align-items: center;
      user-select: none;
      margin-bottom: 2px;
    }

    .wo-tbody {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;

      .no-records {
        height: 150px;
        display: flex;
        align-items: center;
      }
    }

    .wo-th {
      height: 32px;
      padding-left: 18px;
    }

    .sub-table {
      margin-top: 7px;
      margin-bottom: 24px;

      .wo-th {
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
      }

      .wo-tbody {
        .wo-tr {
          background-color: transparent;

          &.new {
            background-color: $protean-white;
          }
        }
      }

      .wo-td {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;

        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          .protean-btn {
            height: 32px;

            &:disabled {
              opacity: 0.7;
            }
          }

          .review-download-dropdown {
            display: flex;
            position: relative;

            button {
              min-width: 32px !important;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #fff;
              border: none !important;
              background-color: $protean-white !important;

              &.dropdown-toggle:after {
                margin-left: auto;
              }

              &:hover {
                background-color: #f0f2f4 !important;
              }

              &.reportBtn {
                height: 32px;
                width: 32px;
                border-radius: 4px !important;
                background-color: $protean-white;
                border: none;
                padding: 0;
                display: flex;
                justify-content: center;
              }

              &.dropdown-item {
                border: none !important;
                padding-left: 10px;
                padding-right: 0;
                background-color: $protean-white;
                min-width: 198px !important;
                color: $protean-dark-gray;
              }

              &.dropdown-item:hover {
                background-color: #f0f2f4 !important;
                color: $protean-dark-gray;
              }
            }

            .dropdown-menu {
              min-width: 200px !important;
              top: 100%;
              background-color: $protean-white !important;
              border-radius: 4px !important;
              border: none;
              margin-top: 0;
              max-height: 100px;
              overflow-y: scroll;

              &::-webkit-scrollbar {
                width: 3px;
                height: 56px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 3.5px;
                background-color: #f0f2f4;
              }
            }
          }
        }

        .cell {
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          border-radius: 15px;
          background-color: $protean-white;
          padding: 5px 9px;
          padding: 5px 9px;

          .color-status {
            display: none;
          }

          &.pending {
            display: flex;
            align-items: center;

            .color-status {
              border-radius: 50%;
              display: inline-flex;
              margin-right: 5px;
              width: 10px;
              height: 10px;
              background-color: #efc675;
            }
          }
        }
      }
    }

    .wo-td {
      height: 48px;
      position: relative;
      padding-left: 18px;
      align-items: center;

      .protean-status-badge {
        padding: 5px 10px;
        border-radius: 12px;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
        background-color: $protean-white;

        .crc {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-flex;
          margin-right: 8px;

          &.overdue {
            background-color: #dc6e6e;
          }

          &.fail,
          &.failed {
            background-color: #dc6e6e;
          }

          &.upcoming {
            background-color: #efc675;
          }

          &.test {
            background-color: #efc675;
          }

          &.retest {
            background-color: #efc675;
          }

          &.pass {
            background-color: #bcd87a;
          }

          &.planned {
            background-color: #bcd87a;
          }
        }
      }

      .wo-badge {
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
        padding: 5px 9px;
        border-radius: 15px;
        background-color: #eaeeef;
      }

      .expanded-icon {
        &.true {
          transform: rotate(90deg);
        }
      }

      .big-Dot {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: #eaeeef;
        display: flex;
        justify-content: center;
        align-items: center;

        .sub-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;

          &.\31 {
            background-color: #dc6e6e;
          }

          &.\32 {
            background-color: #efc675;
          }

          &.\33 {
            background-color: #bcd87a;
          }
        }
      }
    }
  }
}

.atg-item-header {
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-gray;

  min-width: 128px;
  height: 30px;
  align-items: center;
  border-radius: 4px;
  background-color: $protean-white;
  padding-left: 16px;
  margin-right: 2px;

  &.long-header {
    min-width: 35% !important;
  }
}

.atg-item-value {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-black;

  padding-left: 16px;
  width: 100%;
  border-radius: 4px;
  background-color: $protean-white;
  align-items: center;
  margin-right: 20px;
}

.release-detection-item-header {
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-dark-gray;

  height: 30px;
  align-items: center;
  border-radius: 4px;
  background-color: $protean-white;
  padding-left: 12px;
  margin-right: 3px;
  min-width: 35%;

  &.long-header {
    min-width: 65%;
  }
}

.release-detection-item-value {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-black;

  padding-left: 16px;
  border-radius: 4px;
  background-color: $protean-white;
  align-items: center;
  min-width: calc(65% - 4px);

  &.long-header {
    min-width: calc(35% - 4px);
  }
}

.protean-btn {
  border: none;
  border-radius: 4px;
  background-color: $protean-white;
  padding: 9px 20px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: unset;
  letter-spacing: normal;
  text-align: center;
  color: #3a4669;
  height: 39px;

  &.refresh-wo-btn {
    padding: 0;
    margin-left: 14px;
    height: 40px;
    width: 40px;
  }

  &.show-filter-btn {
    height: 32px;
    padding: 3px 20px;
  }

  .ladda-spinner {
    display: none;
  }

  &.hw32 {
    height: 32px;
    width: 32px;
  }

  &.h32 {
    height: 32px;
  }

  &.hw39 {
    height: 39px;
    width: 39px;
  }

  &.hw48 {
    height: 48px;
    width: 48px;
    padding: 0;
  }

  &.hw40 {
    height: 48px;
    width: 48px;
  }

  &.hw28 {
    height: 28px;
    width: 28px;
  }

  &.hw24 {
    height: 24px;
    width: 24px;
  }

  &.hw18 {
    height: 18px;
    width: 18px;
  }

  &:hover {
    background-color: #f0f2f4;
    color: #3a4669;
  }

  &:focus,
  &.sqr:focus {
    color: #3a4669;
    outline: solid 1px #7794e4;
  }

  &.sqr {
    padding: 10px;
  }

  &:disabled {
    color: $protean-gray;
  }

  &-svg-icon {
    padding: 0;
    background: none;
    height: auto;
  }

  &-reject {
    border-radius: 4px;
    background-color: #d96161;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 12px 16px;

    &:hover,
    &:active {
      background-color: #dc6e6e;
      color: #fff;
    }

    &:focus {
      outline: solid 1px #c4c7cf;
      background-color: #d96161;
    }

    &:disabled {
      color: #ffffff;
      background-color: $protean-gray;
    }
  }

  &-default {
    border-radius: 4px;
    background-color: #578ef7;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 12px 16px;

    &:hover,
    &:active {
      background-color: #3c6ecd;
      color: #fff;
    }

    &:focus {
      outline: solid 1px #c4c7cf;
      background-color: #578ef7;
    }

    &:disabled {
      color: #ffffff;
      background-color: $protean-gray;
    }
  }
}

.fs-14 {
  font-size: 14px !important;
}

.protean-alert {
  width: 127px;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 12px 20px;
  border-radius: 4px;

  &-success {
    color: #77972c;
    background-color: #f1ffd0;
  }

  &-warning {
    background-color: #ffdfde;
    color: #97462c;
  }
}

.protean-table {
  width: 100%;
  user-select: none;

  thead {
    tr {
      height: 32px;

      th {
        img {
          margin-left: 5px;
        }

        &.status {
          min-width: 125px;
        }

        &.permit_status {
          min-width: 150px;
        }

        &.priority {
          min-width: 100px;
        }

        &.status_priority {
          min-width: 100px;
        }

        &.assignees {
          min-width: 150px;
          word-break: break-word;
        }

        &.planned_date {
          min-width: 135px;
        }

        &.next_planned_date {
          min-width: 160px;
        }

        &.test_type {
          min-width: 125px;
        }

        &.sensor_category {
          min-width: 150px;
        }

        &.completion_date {
          min-width: 150px;
        }

        &.site_city {
          min-width: 130px;
        }

        &.date {
          min-width: 129px;
        }

        &.site_state {
          min-width: 90px;
        }

        &.inspector_lastname {
          width: 105px;
        }

        &.due_date,
        &.issue_date {
          width: 115px;
        }

        &.agency {
          min-width: 200px;
        }

        &.name {
          min-width: 130px;
        }

        padding: 0 18px;
      }

      border-radius: 4px;
      background-color: $protean-white;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      color: $protean-dark-gray;
    }
  }

  &-analitycs {
    thead {
      tr {
        height: 32px;

        th {
          img {
            margin-left: 5px;
          }

          &.date {
            width: 10%;
          }

          &.priority {
            width: 10%;
          }

          &.client_name {
            width: 10%;
          }

          &.site_name {
            width: 10%;
          }

          &.site_address {
            width: 20%;
          }

          &.site_city {
            width: 10%;
          }

          &.site_state {
            width: 10%;
          }

          &.options {
            width: 5%;
          }

          padding: 0 18px;
        }

        border-radius: 4px;
        background-color: $protean-white;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        color: $protean-dark-gray;
      }
    }

    tbody {
      tr {
        height: 48px;

        &:hover {
          background-color: $protean-white;
        }

        td {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
          height: 48px;
          padding: 16px 20px 16px 20px;
          vertical-align: baseline;
        }
      }
    }
  }

  tbody {
    tr {
      height: 48px;

      &:hover {
        background-color: $protean-white;
      }

      td {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
        height: 48px;
        padding: 16px 20px 16px 20px;
        vertical-align: baseline;
        word-break: normal;

        button {
          word-break: keep-all;
        }

        &.inspector_lastname {
          word-break: normal;
        }

        &.assignees {
          word-break: normal;
        }

        &.inspection_date,
        &.date,
        &.created_on {
          word-break: normal;
        }
      }
    }
  }
}

.no-records {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 300;
}

.no-categories {
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: 300;
}

.protean-pagintaion {
  display: flex;
  align-items: center;

  .arrow {
    border: none;
    background-color: transparent;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    padding: 0;

    &:hover {
      background-color: #f0f2f4;
    }

    &.active {
      background-color: #f0f2f4;
    }

    &:disabled {
      &:hover {
        background-color: initial;
      }

      opacity: 0.5;
    }
  }

  .pagination-button {
    min-width: 32px;
    height: 32px;
    font-size: 14px;
    color: $protean-black;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: #f0f2f4;
    }

    &.active {
      background-color: #f0f2f4;
    }
  }
}

.pagination-size {
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: normal;

  select {
    margin-left: 8px;
    width: 87px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    background-color: #ffffff;
    font-size: 14px;
    color: $protean-black;
  }
}

.protean-filter-block {
  .protean-filter {
    width: 158px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    padding-left: 16px;

    option:first {
      font-size: 13px;
      color: $protean-gray;
    }

    &:invalid {
      color: $protean-gray;
    }

    option:not(:first-of-type) {
      font-size: 13px;
      color: #333;
    }
  }
}

.protean-input {
  border-radius: 4px;
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  padding-left: 16px;

  &.filter {
    &:disabled {
      border-radius: 4px;
      border: solid 1px $protean-white;
      background-color: #ffffff;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-gray;
    }

    &::placeholder {
      font-size: 13px;
      font-weight: 500;
      color: $protean-gray;
    }

    width: 200px;
    height: 32px;
  }
}

.protean-tooltip {
  border-radius: 4px !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: $protean-dark-gray !important;
  padding: 10px !important;

  &.show {
    opacity: 1 !important;
  }

  &:after {
    border: none !important;
  }
}

.z-index-1 {
  z-index: 1 !important;
}

.protean-breadcrumbs {
  background-color: transparent;
  padding: 0 9px;

  .breadcrumb {
    height: 35px;
    align-items: center;
  }
}

.protean-facility-files {
  thead {
    border-radius: 4px;
    background-color: $protean-white;

    tr {
      th {
        border-bottom: none;
      }
    }
  }

  tbody {
    tr {
      td {
        border-top-color: $protean-white;

        div {
          .share-button {
            display: none !important;
          }
        }
      }

      &:first-of-type {
        td {
          border-top: none;
        }
      }
    }
  }
}

.protean-badge {
  padding: 8px 12px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  background-color: $protean-white;
  width: fit-content;
  word-break: keep-all;
  color: $protean-dark-gray;

  &.round {
    &.issuev2 {
      background-color: #e0e8f1;
      color: #3a4669;
    }

    border-radius: 15px;

    .circle {
      display: inline-flex;
      margin-right: 8px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #efc675;

      &.New,
      &.new {
        background-color: #efc675;
      }

      &.Approved,
      &.Resolved,
      &.approved,
      &.resolved {
        background-color: #bcd87a;
      }

      &.Rejected,
      &.rejected {
        background-color: #dc6e6e;
      }
    }
  }

  &.Fail,
  &.Overdue {
    color: #923838;
    background-color: #ffcfcf;
  }

  &.Pass {
    color: #516a17;
    background-color: #f1ffd0;
  }

  &.Upcoming {
    color: #766a4d;
    background-color: #fff396;
  }
}

.protean-test-badge {
  border-radius: 12px;
  background-color: $protean-white;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-gray;
  padding: 5px 10px;
  height: 24px;
  width: fit-content;

  .circle {
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
    height: 10px;
    width: 10px;
  }

  &.Fail,
  &.Overdue {
    .circle {
      background-color: #dc6e6e;
    }
  }

  &.Pass {
    .circle {
      background-color: #bfe85f;
    }
  }

  &.Upcoming {
    .circle {
      background-color: #efc675;
    }
  }

  &.OverduePending {
    .circle {
      background-color: #dc6e6e;
    }
  }

  &.Pending {
    .circle {
      background-color: #efc675;
    }
  }
}

.protean-svg {
  display: inline;
  margin-right: 12px;

  g {
    fill: #3a4669;
  }
}

.protean-navbar {
  height: 72px;
  background-color: #283049;

  .button-title {
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
  }

  .protean-notification-icon {
    font-size: 20px;
  }

  .protean-pill {
    top: -6px;
    left: -5px;
    width: 10px;
    height: 10px;
    background-color: #c3e27a;
    border-color: #283049;
  }

  .protean-logo-block {
    display: flex;
    align-items: center;
  }

  &.non-user {
    .login-link {
      height: 72px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }
}

.page-header.page-header-light {
  .breadcrumb-line {
    height: 60px;
  }

  .breadcrumb-line-light.non-user:not(.breadcrumb-line-component):last-child {
    border-bottom-width: 1px;
  }
}

.protean-page-title {
  font-family: Roboto;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-dark-gray;
}

.protean-default-input {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-black;
  width: 100%;

  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &::placeholder {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-gray;
  }

  &::-moz-placeholder {
    line-height: revert;
  }

  border-radius: 4px;
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  height: 38px;
  padding: 16px 12px;

  &.error {
    border: solid 1px #f6a4a4;
  }

  &:focus {
    border: solid 1px #7a86a9;
    outline: none;
  }

  &:hover {
    border-color: #7a86a9;
  }

  &:disabled {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-gray;
    border: solid 1px $protean-white;
    width: 100%;
  }
}

.isMobileAuth {
  #root {
    min-width: 375px;

    .card {
      width: 300px;

      .card-body {
        padding: 16px;
      }
    }
  }
}

.protean-registration {
  width: 100%;
  height: 100vh;
  background-color: #283049;

  &.reset {
    .content {
      .card {
        .reg-title {
          margin-bottom: 47px;
        }

        .card-body {
          min-height: 362px;
          padding-top: 70px;

          button {
            margin-top: 16px;
          }

          .message {
            margin-top: 80px;
            text-align: center;
            font-size: 19px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-dark-gray;
          }
        }
      }
    }
  }

  &.login {
    .content {
      .card {
        .reg-title {
          margin-bottom: 47px;
        }

        .card-body {
          min-height: 362px;
          padding-top: 70px;

          button {
            margin-top: 16px;
          }

          .login-footer {
            text-align: center;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3f66d4;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    height: 100vh;

    img {
      height: 49px;
      margin-top: 74px;
      margin-bottom: 104px;
    }

    .card {
      width: 579px;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(87, 142, 247, 0.3);
      border: solid 1px #eaeeef;
      background-color: #ffffff;

      .card-body {
        min-height: 551px;

        &.token-error {
          padding: 135px 0;
          height: auto;
          font-size: 19px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.37;
          letter-spacing: normal;
          text-align: center;
          color: $protean-dark-gray;
        }

        padding: 54px 66px 40px 66px;

        input {
          margin-bottom: 8px;
        }

        .reg-title {
          font-family: Roboto;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
        }

        .reg-sub-title {
          font-size: 19px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
          margin-bottom: 32px;
        }

        .protean-default-input[type='password'].error {
          margin-bottom: 0;
        }

        span.error {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #db6969;
          display: block;
          margin-bottom: 8px;
        }

        button {
          margin-bottom: 32px;
        }

        .agree-terms {
          display: flex;
          align-items: baseline;

          span {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
            margin: 0 2px 0 5px;
            cursor: pointer;
          }

          a {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #3f66d4;
          }
        }

        .reg-singin {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;

          a {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #3f66d4;
          }
        }

        .protean-default-input {
          &:disabled {
            font-family: Roboto;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            -webkit-text-fill-color: $protean-gray;
            opacity: 1;
            color: $protean-gray;
          }
        }
      }
    }
  }
}

.svg-fill-icon {
  margin-bottom: 2px;
  fill: $protean-dark-gray;

  path {
    fill: $protean-dark-gray;
  }

  g {
    fill: $protean-dark-gray;
  }

  &.image {
    fill: none;

    g {
      fill: none;

      path {
        &:nth-child(2) {
          fill: $protean-dark-gray;
        }

        fill: none;
      }
    }
  }
}

.protean-select {
  position: relative;

  img {
    position: absolute;
    right: 16px;
    top: 10px;
  }

  .selected-text {
    margin: 0 8px;
    width: inherit;
    padding: 8px 12px;
    appearance: none;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    background-color: #ffffff;

    span {
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-gray;
    }
  }

  .select-options {
    position: absolute;
    width: calc(100% - 16px);
    left: 8px;
    top: 0;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #eaeeef;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    z-index: 9;

    li {
      &:first-of-type {
        margin-top: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-of-type {
        margin-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &.active {
        background-color: $protean-white;
      }

      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;

      margin: 4px 0;
      list-style-type: none;
      padding: 8px 12px;
      cursor: pointer;
    }

    li:hover {
      background-color: $protean-white;
    }
  }
}

.protean-files-page {
  .card-body {
    padding: 8px 33px !important;

    .protean-cabinet-files {
      .protean-file-table {
        font-size: 14px !important;
        font-weight: normal !important;
        color: $protean-black !important;

        thead {
          &:first-child {
            tr {
              background-color: $protean-white;

              th:first-child {
                text-align: center;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }

              th:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
          }

          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;

          tr {
            // padding: 8px 20px !important;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-dark-gray;

            th {
              padding: 9px 20px;
              height: 32px;
              border-bottom: none;

              &.name {
                width: auto;
              }

              &.id {
                width: 4%;
              }

              &.last_modified {
                width: 10%;
              }

              &.created_on {
                width: 10%;
              }

              &.Options {
                width: 5%;
              }
            }

            th.sorting:after {
              content: url('../images/sorting-table.svg');
              margin-top: 0;
              top: 30%;
              right: auto;
              margin-left: 8px;
            }

            th.sorting:nth-of-type(2) {
              width: 70%;
            }

            th.sorting:nth-of-type(3) {
              min-width: 135px;
            }

            th.sorting:before,
            .sorting_asc:before,
            .sorting_desc:before {
              content: '';
              display: none;
            }

            th.sorting_asc:after {
              content: url('../images/sorting-table-up.svg');
              margin-top: 0;
              top: 25%;
              right: auto;
              margin-left: 8px !important;
            }

            th.sorting_desc:after {
              content: url('../images/sorting-table-down.svg');
              margin-top: 0;
              margin-left: 8px;
              top: 39%;
              right: auto;
            }
          }
        }

        tbody {
          &.isDragActive {
            border-radius: 4px;
            border: solid 1px #3f66d4;
            background-color: $protean-white !important;
          }

          tr {
            &.selected,
            &:hover {
              background-color: $protean-white;
            }

            td {
              &:first-of-type {
                vertical-align: middle;
              }

              border-top: none;
              vertical-align: top;

              .sub-breadcrumbs {
                padding-left: 7px;

                .protean-btn {
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $protean-gray;

                  border: none;
                  background-color: transparent;
                  margin-right: 4px;
                  padding: 0;
                  height: 39px;

                  i {
                    font-size: 12px;
                  }

                  &:hover {
                    color: $protean-dark-gray;
                  }
                }
              }
            }
          }

          .fancytree-node {
            cursor: default;

            .fancytree-title.file {
              cursor: default;
              color: $protean-black !important;
            }

            .fancytree-icon {
              margin-top: 0;
              color: $protean-black !important;
            }
          }

          .fancytree-ico-ef {
            .fancytree-icon {
              margin-top: 0;

              td:nth-of-type(2) {
                .fancytree-title {
                  font-size: 14px;
                  font-weight: normal;
                  color: $protean-black !important;
                }
              }
            }

            td:nth-of-type(2) {
              .fancytree-title {
                font-size: 14px;
                font-weight: normal;
                color: $protean-black !important;
              }
            }
          }
        }
      }
    }
  }

  .card-header {
    padding: 8px 33px;

    .header-elements-inline {
      .card-title {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }

      // padding-top: 22px;
      // padding-bottom: 8px;
    }
  }

  .card-header.cabinet-actions {
    padding: 8px 33px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

    .protean-btn {
      line-height: normal;
    }

    .tooltip-block {
      display: flex;
      margin-left: auto;

      .ecabinet-tooltip {
        width: 200px;
        padding: 14px;
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: $protean-dark-gray;
      }
    }
  }

  .protean-breadcrumbs-main {
    padding-top: 8px !important;
    padding-bottom: 8px !important;

    .protean-e-cabinet-breadcrumbs {
      height: auto;

      .breadcrumb {
        div {
          display: flex;
          margin: 4px 0;

          .protean-btn {
            padding: 8px;
            line-height: normal;
            height: 31px !important;
          }
        }
      }
    }
  }
}

.protean-checkbox {
  appearance: none;

  &.disabled {
    opacity: 0.5;
  }

  &:before {
    display: block;
    width: 14px;
    height: 14px;
    content: '';
    background: #fff;
    background-image: url('../images/checkboxOff.svg');
  }

  &.issues {
    &:before {
      background-image: url('../images/plus-checkbox.svg');
    }
  }

  &.group-issues {
    &:before {
      background-image: url('../images/minus-checkbox.svg');
    }
  }

  &:checked:before {
    background-image: url('../images/checkboxOn.svg');
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.protean-modal {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #eaeeef;
  background-color: #ffffff;

  // width: 511px;
  // height: 238px;
  .card-title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: $protean-black;
  }

  .card-body {
    .label {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-gray;
      margin-bottom: 16px;
    }
  }
}

.base-modal {
  &.card {
    padding: 0;

    .card-header {
      padding: 32px;

      .card-title {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }
    }

    .card-body {
      padding: 32px;
      padding-top: 0;

      &.events {
        padding-right: 16px;

        .search-input {
          position: relative;
          margin-bottom: 16px;

          svg {
            position: absolute;
            right: 7px;
            top: 7px;
            height: 24px;
            width: 24px;

            g {
              path:nth-child(2) {
                fill: $protean-gray;
              }
            }
          }
        }

        .events-wrapper {
          min-height: 230px;
          overflow: auto;
          max-height: 500px;
          padding-right: 14px;

          &::-webkit-scrollbar {
            width: 3px;
            height: 56px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 3.5px;
            background-color: #f0f2f4;
          }

          .event {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 8px;
            padding: 9px 7px;
            border-radius: 4px;
            background-color: $protean-white;
            font-size: 11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-dark-gray;

            .event-description {
              font-size: 11px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #3a4669;
            }

            .event-icon {
              display: inline-flex;
            }
          }
        }
      }
    }
  }
}

.no-rows {
  &.isDragActive {
    border-radius: 4px;
    border: solid 1px #3f66d4;
    background-color: $protean-white !important;
  }

  height: 100px;
  line-height: 100px;
  color: $protean-dark-gray;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.fancytree-drop-target {
  opacity: 1;

  &.fancytree-drop-accept {
    // opacity: 1;
    td {
      background-color: $protean-white !important;

      .fancytree-node {
        background-color: transparent !important;
      }
    }
  }
}

// .fancytree-drop-target.fancytree-drop-accept a {
//     background-color: #2196F3 !important;
//     color: #fff !important;
//     text-decoration: none;
// }

.fancytree-drag-source {
  opacity: 0;

  .fancytree-drag-accept {
    opacity: 1;
  }

  .fancytree-drag-reject {
    opacity: 1;
  }
}

.protean-analytics {
  &.card {
    padding: 0;

    .card-header {
      padding: 32px 32px 32px 32px;
      margin: 0;

      .title {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
        margin-bottom: 12px;
      }

      .description {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-dark-gray;
      }
    }

    .card-body {
      padding: 16px;
      height: fit-content;
      position: relative;

      .fetch-filters {
        &.false {
          .Collapsible {
            .Collapsible__contentOuter {
              overflow: hidden;
            }
          }
        }

        &.true {
          .Collapsible {
            .Collapsible__contentOuter {
              overflow: visible !important;
            }
          }
        }

        .filter-date {
          & > span {
            display: block;
            margin-bottom: 8px;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }

          .protean-select {
            .select-options {
              left: 0;
              width: calc(100% - 8px);
            }

            .selected-text {
              margin-left: 0;
            }
          }
        }

        .fetch-filter {
          span:first-of-type {
            display: block;
            margin-bottom: 8px;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }
        }
      }
    }
  }
}

.facility-tabs {
  &.connection {
    .facility-tab {
      padding-bottom: 2px;
      cursor: default;

      .tab-inner {
        display: flex;
        flex-direction: column;

        .inner-title {
          font-family: Roboto;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #283049;
          margin-bottom: 2px;
        }

        .inner-sub {
          height: 15px;
          font-family: Roboto;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $protean-gray;
        }
      }

      &.active {
        &:before {
          background-color: #578ef7;
        }
      }
    }
  }

  .facility-tab {
    font-size: 13px;
    font-weight: 500;
    color: $protean-gray;

    &.active {
      color: #283049;

      &:before {
        background-color: #bcd87a;
      }
    }
  }
}

.report-list {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(87, 142, 247, 0.3);
  border: solid 1px #eaeeef;
  margin-bottom: 44px;
}

button:focus,
input:focus {
  outline: none;
}

.card.protean-report {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  box-shadow: none;
  padding: 32px;
  margin-bottom: 0;

  &.fix-picker {
    @media screen and (max-height: 970px) {
      min-height: 534px;
    }
  }

  &.fix-picker-remedy {
    @media screen and (max-height: 970px) {
      min-height: 579px;
    }
  }

  &.fix-picker-regulator {
    @media screen and (max-height: 970px) {
      min-height: 594px;
    }
  }

  &.fix-picker-hot-topic {
    @media screen and (max-height: 970px) {
      min-height: 594px;
    }
  }

  &.fix-picker-cathodic {
    @media screen and (max-height: 970px) {
      min-height: 594px;
    }
  }

  &.fix-picker-widget {
    @media screen and (max-height: 970px) {
      min-height: 650px;
    }
  }

  span.report-title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    margin-bottom: 30px;
    cursor: pointer;

    svg {
      margin-bottom: 3px;
    }
  }

  &:last-of-type {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-top: 5px;
  }

  .card-header {
    position: relative;
    padding: 0;
    margin-bottom: 30px;

    .report-title {
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
    }

    .report-description {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
    }
  }

  .card-body {
    padding: 0;
    margin-left: 0;
    margin-right: 0;

    .col-12 {
      max-width: 600px;
    }

    .col-6 {
      .keyword-hints {
        border-radius: 4px;
        background-color: $protean-white;
        padding: 17px;

        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-dark-gray;

        ul {
          padding-left: 11px;

          .hint-row {
            ul > li {
              margin-top: 12px;
            }

            margin-bottom: 19px;

            code {
              border-radius: 4px;
              background-color: #ffffff;
              padding-left: 14px;
              padding-right: 14px;

              font-size: 12px;
              font-family: 'PT Mono', monospace;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-dark-gray;
            }
          }
        }
      }

      .report-input {
        margin-bottom: 24px;

        &:last-of-type {
          margin-bottom: 24px;
        }
      }
    }

    .switch-report {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      display: flex;
      margin-bottom: 24px;

      .protean-toggler {
        margin-left: 0;
        margin-right: 8px;
      }
    }

    .report-notification-text {
      max-width: 465px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
      padding: 24px;
      border-radius: 4px;
      background-color: $protean-white;
      margin-bottom: 24px;
    }

    .period_row {
      display: flex;
      margin-bottom: 60px;

      .report-input {
        width: 40%;
        margin-bottom: 0 !important;

        .report-input-title {
          margin-bottom: 8px;
          display: block;
        }
      }

      .or-title {
        span {
          margin-top: 16px;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
        }

        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .report-input {
      &:last-of-type {
        margin-bottom: 60px;
      }

      margin-bottom: 24px;

      .clearBtn {
        position: absolute;
        top: 23px;
        right: -26px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .report-input-title {
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;

        &.checkbox-title {
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
        }
      }

      .report-input-hint {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
      }

      .type-checkbox {
        display: flex;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: initial;
        letter-spacing: normal;
        color: $protean-dark-gray;

        .protean-checkbox {
          margin-right: 8px;

          &:before {
            width: 18px;
            height: 18px;
            background-size: 18px;
          }

          width: 18px;
          height: 18px;
        }
      }

      .sheet-types-check {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;

        &:first-of-type {
          margin-top: 12px;
        }

        .sheet-input {
          border-radius: 4px;
          border: solid 1px #eaeeef;
          width: 285px;
          height: 28px;
          padding-left: 16px;

          &:hover {
            border: solid 1px #7a86a9;
          }

          &:focus {
            outline: none;
            border-color: transparent;
            box-shadow: 0 0 0 1px #666;
          }
        }

        .report-input-checkbox {
          &:before {
            display: block;
            width: 14px;
            height: 14px;
            content: '';
            background: #fff;
            background-image: url('../images/checkboxOff.svg');
          }

          &:checked:before {
            background-image: url('../images/checkboxOn.svg');
            background-size: 14px;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        align-items: center;
        margin: 6px 0;

        div {
          align-items: center;
          padding: 6px 10px;

          &.type-title {
            border-radius: 4px;
            background-color: $protean-white;
            padding: 6px 10px;
          }

          span {
            margin-left: 8px;
          }
        }

        &.disabled > * {
          opacity: 0.5;
        }
      }

      .double-select {
        width: 100%;

        .double-select-item {
          width: 48%;

          &:first-of-type {
            margin-right: 4%;
          }
        }
      }
    }

    &.report-doc-section {
      margin-top: 24px;

      .faq-sub-title {
        font-size: 19px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-dark-gray;
      }
    }
  }
}

.share-modal-subtitle {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-dark-gray;
  margin-bottom: 16px;
}

.protean-input-with-button {
  &.email-box {
    .email-box-content,
    .email-box-header {
      margin-top: 19px;

      .user-line {
        margin-bottom: 4px;
        justify-content: space-between;
        flex-direction: row;
        display: flex;

        .user-name {
          &-title {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }

          align-items: center;
          display: flex;
          width: 38%;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
        }

        .user-till {
          &-title {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }

          align-items: center;
          display: flex;
          width: 28%;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
        }

        .share-buttun {
          width: 19%;
        }

        .user-perms {
          &-title {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }

          font-size: 14px;
          color: $protean-dark-gray;
          width: 19%;
          margin-top: 4px;
          align-items: center;
          display: flex;
        }

        .user-action {
          width: 10%;
          margin-top: 4px;
          align-items: center;
          display: flex;

          .remove-share-btn {
            margin-left: 24px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 3px;
        height: 56px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #f0f2f4;
      }
    }
  }

  .input-title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;
    display: block;
    width: 100%;
    margin-bottom: 8px;
  }

  .input-group-append {
    margin-left: -2px;

    .protean-btn,
    .protean-btn-default {
      height: 38px;
      z-index: 1;
    }
  }

  input.input-with-button {
    &.is-invalid {
      background: transparent;
      border-color: #f6a4a4;
    }

    height: 38px;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    background-color: #ffffff;
  }
}

.share-modal-content {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-dark-gray;
  justify-content: space-between;

  .share-user {
    width: 48%;
  }

  .share-till {
    width: 28%;
  }

  .share-button {
    width: 19%;
  }
}

.share-perms {
  font-size: 14px;
  color: $protean-dark-gray;
  margin-top: 8px;
}

.qr-share {
  #qrcode {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    img {
      display: flex;
      width: 200px;
      height: 200px;
    }
  }
}

.analytics-message {
  .message-title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $protean-black;
    padding-left: 9px;
  }

  .message-text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    padding: 0 9px;

    &:last-of-type {
      margin-bottom: 20px;
    }

    &:first-of-type {
      margin-top: 15px;
    }
  }
}

.card.card-tems {
  padding: 32px;

  .card-header {
    position: relative;
    padding: 0;
    margin: 14px 0;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;

    .last-updated {
      position: absolute;
      bottom: -32px;
      right: 0;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: $protean-dark-gray;
    }
  }

  .card-body {
    margin-top: 32px;

    .item-title {
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
      margin: 32px 0;
    }

    .item-sub-title {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: justify;
      color: $protean-dark-gray;
      margin-bottom: 4px;
    }

    .terms-text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: justify;
      color: $protean-dark-gray;
    }

    .terms-inner-text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: justify;
      color: $protean-dark-gray;
      margin-left: 24px;
    }

    .terms-link {
      color: #616673;

      &:hover {
        color: #1d1d1d;
        text-decoration: underline;
      }
    }
  }
}

.fuel-system {
  position: relative;

  .fuel-sync {
    position: absolute;
    right: 0;
    top: -40px;
  }

  .fuel-item {
    display: flex;
    flex-direction: row;
    padding: 0 24px 14px 24px;

    .vertical-tabs {
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      width: 144px;

      .triangle {
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-left: 14px solid #e0e8f1;
        border-bottom: 16px solid transparent;
      }

      .vertical-tab {
        position: relative;
        width: 130px;
        height: 32px;
        margin-bottom: 8px;
        border-radius: 2px;
        background-color: #e0e8f1;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $protean-gray;
        border-color: transparent;

        &-main {
          color: $protean-dark-gray;
          border-radius: 2px !important;

          &.active {
            color: $protean-dark-gray !important;
          }

          width: 161px;

          .tab-icon {
            position: absolute;
            right: 8px;
            top: 7px;
          }
        }

        &.active {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          color: #3a4669;
        }
      }
    }

    .fuel-tab-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      height: fit-content;

      .fuel-details {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .fuel-content {
          width: 49%;
        }

        .fuel-content-devider {
          width: 100%;
          height: 10px;
        }

        .fuel-system-content-item {
          .facility-item-header {
            min-width: 206px;
          }
        }
      }
    }
  }
}

.protean-dashboard {
  &.card {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(87, 142, 247, 0.3);
    border: solid 1px #eaeeef;
    background-color: #ffffff;

    .protean-devider {
      border-bottom: solid 5px $protean-white;
    }
  }

  .calendar-widget {
    padding: 32px 29px 34px 29px;

    .calendar {
      padding: 35px 11px 0 11px;

      .calendar-info {
        align-items: center;
        display: flex;

        .week {
          border-radius: 15px;
          background-color: $protean-white;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          padding: 5px 9px;
          margin-left: 15px;
          margin-right: 9px;
        }
      }

      .calendar-header {
        height: 27px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .calendar-col {
          flex: 2;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #c4c7cf;

          .current {
            border-bottom: solid 2px #578ef7;
            padding-right: 5px;
            padding-bottom: 5px;
          }

          a,
          span {
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #c4c7cf !important;
          }
        }
      }

      .calendar-body {
        border: solid 2px #eaeeef;
        border-bottom: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .calendar-col {
          max-height: 580px;
          overflow: hidden;
          padding: 7.5px 3.5px 7.5px 3.5px;
          flex: 2;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #c4c7cf;
          border-left: solid 2px #eaeeef;
          border-right: solid 2px #eaeeef;

          .facility-row {
            margin-bottom: 12px;

            .calendar-item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              height: 44px;
              padding: 5px 6px;
              border-radius: 4px;
              background-color: $protean-white;
              font-family: Roboto;
              font-size: 11px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-dark-gray;
              display: none;
              margin-bottom: 4px;

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              min-width: 0;

              div {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 100%;
                cursor: default;
              }

              .event-icon {
                margin: 0 3px 4px 0;
                display: inline-flex;
              }

              .item-details {
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-dark-gray;
              }
            }
          }
        }
      }

      .calendar-footer {
        border: solid 2px #eaeeef;
        border-top: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .calendar-col {
          flex: 2;
          padding: 7.5px 3.5px 7.5px 3.5px;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #c4c7cf;
          border-left: solid 2px #eaeeef;
          border-right: solid 2px #eaeeef;

          .calendar-item {
            display: flex;
          }
        }
      }
    }
  }

  .dashboard-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;

    &-24 {
      font-size: 24px;
    }

    &-20 {
      font-size: 20px;
    }
  }
}

.analitycs-tooltip {
  width: 462px;
  // height: 424px;
  padding: 14px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: $protean-dark-gray;

  .protean-btn.icon {
    &:first-of-type {
      padding-top: 4px;
    }

    padding: 6px;
  }

  ul {
    padding: 0;
    margin: 0;

    & > li {
      font-size: 20px;
      margin-left: 19px;
      margin-bottom: 5px;
      line-height: 10px;

      span {
        font-size: 13px;
      }
    }
  }

  .title-tooltip {
    font-size: 13px;
    font-weight: 900;
    color: $protean-dark-gray;
  }
}

.protean-loader {
  color: #7a86a9 !important;
}

.protean-footer {
  margin-top: 110px;
  border-top: #f2f4f6 5px solid;
  height: 93px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  .rights-block {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;

    svg {
      margin-right: 16px;
    }
  }

  .terms-block {
    a {
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
    }
  }
}

.trends-report {
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 41px;
  border-radius: 2px;
  background-color: $protean-white;

  min-width: 200px !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-dark-gray;
  border: solid 1px #eaeeef !important;

  .report-icon {
    g {
      fill: $protean-dark-gray;
    }
  }

  &:hover {
    .report-icon {
      g {
        fill: #333;
      }
    }
  }
}

.nav-item {
  &.custom-nav-item-lock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .nav-link.main-icon {
      display: flex !important;
      align-items: center;
      padding-top: 14px !important;
      padding-bottom: 14px !important;

      .sidebar-toggle-icon {
        display: flex;
      }

      &.open-icon {
        &.true {
          width: 50%;

          &:disabled {
            pointer-events: none;
            opacity: 0.5;
          }

          &:hover {
            background-color: unset;
          }
        }
      }

      &.lock-icon {
        display: none !important;
        width: 24px;
        padding: 0;
        margin-right: 14px;
        cursor: default;

        &:hover {
          background-color: unset;
        }

        &.true {
          display: flex !important;
        }
      }
    }
  }

  .nav-link.main-icon {
    display: flex !important;
    align-items: center;
    padding-top: 14px !important;
    padding-bottom: 14px !important;

    .sidebar-toggle-icon {
      display: flex;
    }
  }

  .sidebar-devider {
    // min-width: 28px;
    margin: 0 25px;
    border-bottom: solid 1px #eaeeef;
    box-shadow: 0 2px 4px 0 rgba(87, 142, 247, 0.3);
  }
}

.hide {
  visibility: hidden;
}

.image-loader {
  height: 116px;
  width: 154px;

  & > div:first-of-type {
    min-height: 116px;
  }
}

.image-page {
  width: 100%;

  img {
    width: auto;
  }
}

.list-icons {
  margin-left: 25px;
  border-radius: 4px;
  background-color: #f0f2f4;

  .items-wrapper {
    height: inherit;

    .dropdown-menu.dropdown-menu-left {
      border-color: transparent;
      border-radius: 4px;
      background-color: #f0f2f4;

      .dropdown-item {
        &:hover {
          background-color: #eaeeef !important;
        }

        svg {
          margin-right: 8px;
        }
      }
    }

    .btn.dropdown-toggle {
      height: inherit;
    }
  }
}

.z3 {
  z-index: 3;
}

.link_as_text {
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #333;

  &:hover {
    color: #333;
  }
}

.protean-wo {
  // padding: 17px;
  border-radius: 4px;
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  // margin: 17px;
  padding: 0;

  .card-header {
    margin: 17px;
    padding: 16px;

    .title {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
    }

    .description {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
    }
  }

  .items {
    padding: 0;
    display: flex;

    &.Contractor {
      flex-direction: column;
    }

    &.Project {
      flex-direction: column;
    }

    &.Default {
      flex-direction: column;

      .wo-item:first-of-type {
        .nav.nav-tabs.nav-tabs-bottom.nav-justified.facility-tabs {
          display: flex;
        }
      }
    }

    .devider {
      height: 5px;
      margin: 16px 0;
      background-color: $protean-white;
    }

    .wo-item {
      margin: 16px;
      display: flex;
      flex-direction: column;

      .title {
        margin-left: 16px;
        margin-bottom: 32px;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
        width: 100%;
      }

      .fetch-filters {
        &.false {
          .Collapsible {
            .Collapsible__contentOuter {
              overflow: hidden;
            }
          }
        }

        &.true {
          .Collapsible {
            .Collapsible__contentOuter {
              overflow: visible !important;
            }
          }
        }

        .filter-date {
          & > span {
            display: block;
            margin-bottom: 8px;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }

          .protean-select {
            .select-options {
              left: 0;
              width: calc(100% - 8px);
            }

            .selected-text {
              margin-left: 0;
            }
          }
        }

        .fetch-filter {
          span:first-of-type {
            display: block;
            margin-bottom: 8px;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }

          .clear-button {
            position: absolute;
            right: -32px;
            top: 23px;
            height: 38px;
            width: 38px;

            svg {
              height: 23px;
              width: 23px;
            }
          }
        }
      }
    }
  }

  .extra {
    display: flex;
    justify-content: flex-end;

    .pagination-size {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
    }

    .export-download-dropdown {
      display: flex;
      position: relative;

      button {
        min-width: 200px !important;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        border: solid 1px #eaeeef !important;
        background-color: #578ef7 !important;

        &.dropdown-toggle:after {
          margin-left: auto;
        }

        &:hover {
          background-color: #3c6ecd !important;
          color: #fff;
        }

        &.reportBtn {
          height: 39px;
          border-radius: 4px !important;
          border: solid 1px #eaeeef;
          background-color: #578ef7;
        }

        &.dropdown-item {
          border: none !important;
          padding-left: 10px;
          padding-right: 0;
          background-color: #578ef7;
          min-width: 198px !important;
        }

        &.dropdown-item:hover {
          background-color: #3c6ecd !important;
          color: #fff;
        }
      }

      .dropdown-menu {
        min-width: 200px !important;
        top: 100%;
        background-color: #578ef7 !important;
        border-radius: 4px !important;
        border: solid 1px #eaeeef;
        margin-top: 0;
      }
    }
  }

  .wo-table-content {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;

    .no-records {
      min-height: 150px;
      align-items: center;
    }

    .wo-table {
      border-spacing: 0;

      .wo-tr {
        border-radius: 4px;
        background-color: $protean-white;
        align-items: center;
        user-select: none;
        margin-bottom: 2px;
      }

      .wo-th {
        height: 32px;
        padding-left: 18px;
      }

      .sub-table {
        margin-top: 7px;
        margin-left: 33px;

        .wo-th {
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
        }

        .wo-td {
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;

          .cell {
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
            border-radius: 15px;
            background-color: $protean-white;
            padding: 5px 9px;
            padding: 5px 9px;

            .color-status {
              display: none;
            }

            &.pending {
              display: flex;
              align-items: center;

              .color-status {
                border-radius: 50%;
                display: inline-flex;
                margin-right: 5px;
                width: 10px;
                height: 10px;
                background-color: #efc675;
              }
            }
          }
        }
      }

      .wo-td {
        height: 48px;
        position: relative;
        padding-left: 18px;
        align-items: center;

        .wo-badge {
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          padding: 5px 9px;
          border-radius: 15px;
          background-color: #eaeeef;
        }

        .expanded-icon {
          &.true {
            transform: rotate(90deg);
          }
        }
      }
    }

    .site-table {
      margin-top: 10px;
    }
  }
}

@keyframes toActive {
  from {
    background-color: #e0e8f1;
  }

  to {
    background-color: #bcd87a;
  }
}

@keyframes toInactive {
  from {
    background-color: #bcd87a;
  }

  to {
    background-color: #e0e8f1;
  }
}

.protean-toggler {
  width: 32px;
  height: 18px;
  margin: 0 0 0 8px;
  padding: 1px 1px 1px 1px;
  border-radius: 9px;
  background-color: #e0e8f1;
  display: flex;
  cursor: pointer;
  // justify-content: flex-start;
  animation: toInactive 0.3s;
  position: relative;

  &.green-double-side {
    animation: unset;
    margin-right: 8px;

    &.false,
    &.true {
      animation: unset;
      background-color: #bcd87a;
    }

    &.disabled {
      background-color: #e0e8f1 !important;
    }
  }

  &.true {
    background-color: #bcd87a;
    // justify-content: flex-end;
    animation: toActive 0.3s;

    .toggler {
      left: 45%;
    }
  }

  .toggler {
    position: absolute;
    transition: all 0.1s linear;
    left: 5%;
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 9px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
  }
}

.filter-icon {
  &.active {
    .search-icon-path {
      fill: #578ef7;
    }
  }
}

.protean-inspections {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(87, 142, 247, 0.3);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  padding: 0;
  padding-bottom: 250px;

  .protean-tooltip {
    color: red;
    border-radius: 4px !important;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: $protean-dark-gray !important;
    padding: 10px !important;

    &.show {
      opacity: 1 !important;
    }

    &:after {
      border: none !important;
    }
  }

  .card-header {
    margin: 17px;
    padding: 16px;

    .title {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
    }

    .description {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
    }
  }

  .filters {
    margin: 0 17px 17px 17px;
    padding: 0 16px;
  }

  .tables {
    padding: 0;
    margin: 0 17px;
    display: flex;
    position: relative;

    .facility-tab {
      font-size: 14px;
    }

    .fetch-filters {
      &.false {
        .Collapsible {
          .Collapsible__contentOuter {
            overflow: hidden;
          }
        }
      }

      &.true {
        .Collapsible {
          .Collapsible__contentOuter {
            overflow: visible !important;
          }
        }
      }

      .filter-date {
        & > span {
          display: block;
          margin-bottom: 8px;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
        }

        .protean-select {
          .select-options {
            left: 0;
            width: calc(100% - 8px);
          }

          .selected-text {
            margin-left: 0;
          }
        }
      }

      .fetch-filter {
        span:first-of-type {
          display: block;
          margin-bottom: 8px;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
        }

        .clear-button {
          position: absolute;
          right: -32px;
          top: 23px;
          height: 38px;
          width: 38px;

          svg {
            height: 23px;
            width: 23px;
          }
        }
      }
    }

    .refresh {
      width: auto;
      margin-right: auto;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .table-type {
      display: flex;
      margin-bottom: 30px;
      justify-content: space-between;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;

      .switch {
        div {
          display: flex;
        }

        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
        display: flex;
        flex-direction: row;
      }
    }

    .crc {
      &.approved,
      &.Approved {
        background-color: #bcd87a;
      }

      &.rejected,
      &.Rejected {
        background-color: #dc6e6e;
      }

      display: inline-flex;
      height: 10px;
      width: 10px;
      border-radius: 12px;
      background-color: #efc675;
      margin-right: 8px;
    }

    .table-content {
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;

      .extra {
        display: flex;
        justify-content: flex-end;

        .pagination-size {
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
        }

        .export-download-dropdown {
          display: flex;
          position: relative;

          button {
            min-width: 200px !important;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            border: solid 1px #eaeeef !important;
            background-color: #578ef7 !important;

            &.dropdown-toggle:after {
              margin-left: auto;
            }

            &:hover {
              background-color: #3c6ecd !important;
              color: #fff;
            }

            &.reportBtn {
              height: 39px;
              border-radius: 4px !important;
              border: solid 1px #eaeeef;
              background-color: #578ef7;
            }

            &.dropdown-item {
              border: none !important;
              padding-left: 10px;
              padding-right: 0;
              background-color: #578ef7;
              min-width: 198px !important;
            }

            &.dropdown-item:hover {
              background-color: #3c6ecd !important;
              color: #fff;
            }
          }

          .dropdown-menu {
            min-width: 200px !important;
            top: 100%;
            background-color: #578ef7 !important;
            border-radius: 4px !important;
            border: solid 1px #eaeeef;
            margin-top: 0;
          }
        }
      }

      .no-records {
        min-height: 150px;
        align-items: center;
      }

      .wo-table {
        border-spacing: 0;

        .wo-tr {
          border-radius: 4px;
          background-color: $protean-white;
          align-items: center;
          user-select: none;
          margin-bottom: 2px;
        }

        .wo-th {
          height: 32px;
          padding-left: 18px;
        }

        .sub-table {
          margin-top: 7px;
          margin-left: 33px;

          .wo-th {
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }

          .wo-td {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-black;

            .cell {
              font-family: Roboto;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-gray;
              border-radius: 15px;
              background-color: $protean-white;
              padding: 5px 9px;
              padding: 5px 9px;

              .color-status {
                display: none;
              }

              &.pending {
                display: flex;
                align-items: center;

                .color-status {
                  border-radius: 50%;
                  display: inline-flex;
                  margin-right: 5px;
                  width: 10px;
                  height: 10px;
                  background-color: #efc675;
                }
              }
            }
          }
        }

        .wo-thead {
          .wo-tr {
            .wo-th {
              font-size: 13px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-dark-gray;
              position: relative;
            }

            .date-filter {
              display: none;

              &.date {
                display: block;

                .picker {
                  position: absolute;
                  top: 5px;
                  left: 7px;
                  display: none;
                  z-index: 9;

                  .close-picker {
                    display: none;

                    &.show {
                      display: block;
                      position: absolute;
                      top: 5px;
                      right: 10px;
                    }
                  }

                  &.true {
                    display: block;

                    .datepicker-block {
                      .icon {
                        display: none;
                      }

                      input {
                        width: 98%;
                        height: 26px;
                        padding-left: 6px;
                        // width: inherit;
                        // height: inherit;
                      }

                      .custom-picker {
                        top: 25px;
                      }
                    }
                  }

                  .sortNone {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 28px;
                  }

                  .sortUp {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 28px;
                    margin-bottom: 0 !important;
                  }

                  .sortDown {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 28px;
                    margin-top: 0 !important;
                  }
                }
              }
            }

            .filter-select.date {
              display: none;
            }

            .hide {
              display: none;
            }
          }
        }

        .wo-tbody {
          .highlight {
            background-color: $protean-white !important;
          }

          .status-cell {
            width: 130px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .wo-badge {
              height: 24px;
              align-items: center;
              display: flex;

              .completed {
                background-color: #bcd87a;
              }
            }
          }

          .wo-tr.inspections {
            background-color: #ffffff;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-black;
            border-bottom: $protean-white solid 1px;

            .wo-badge {
              font-family: Roboto;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-gray;
              padding: 5px 9px;
              border-radius: 15px;
              background-color: #eaeeef;
            }
          }
        }

        .wo-td {
          /* height: 48px; */
          position: relative;
          padding-left: 18px;
          align-items: center;
          word-break: break-word;

          .wo-badge {
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
            padding: 5px 9px;
            border-radius: 15px;
            background-color: #eaeeef;
            word-break: keep-all;
          }

          .expanded-icon {
            &.true {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
}

#portal-table-select-hack.runout-filter-select {
  .wrapper-select.true {
    width: 275px;

    .select-item {
      align-items: center;
    }
  }
}

.wrapper-select {
  display: none;

  &.true {
    display: block;
  }

  position: absolute;
  z-index: 999;
  width: 212px;
  padding: 11px 10px 18px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  top: 100%;
  left: 12px;
  cursor: default;

  .search-icon {
    right: 14px;
    top: 18px;
    fill: $protean-dark-gray;
    position: absolute;
    cursor: pointer;
  }

  .search-input {
    width: 100%;
    height: 32px;
    margin: 0 1px 12px 0;
    padding: 6px 22px 6px 8px;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    background-color: #ffffff;
  }

  .filter-value {
    display: flex;
    flex-wrap: wrap;

    .filerByBlock {
      padding: 4px 10px;
      border-radius: 15px;
      background-color: $protean-white;
      font-size: 12px;
      font-weight: bold;
      color: $protean-dark-gray;
      width: fit-content;
      margin-bottom: 8px;
      margin-right: 8px;
      display: flex;
      align-items: center;

      svg {
        margin-left: 4px;
      }
    }
  }

  .results {
    max-height: 200px;
    overflow-y: auto;

    .no-results {
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
    }

    .select-item {
      margin: 4px 0px;
      display: flex;

      // align-items: center;
      input {
        margin-top: 1px;
      }

      .dot-color-badge {
        margin: 0 !important;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #f7f9fa;
        display: inline-flex;

        .dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin: auto !important;
        }
      }

      span {
        margin-left: 10px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    button {
      // display: flex;
      width: 48%;
    }
  }
}

#portal-table-select-hack {
  position: absolute;

  .wrapper-select {
    display: none;

    &.true {
      display: block;
    }

    position: absolute;
    z-index: 999;
    width: 212px;
    padding: 11px 10px 18px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #eaeeef;
    background-color: #ffffff;
    top: 100%;
    left: 12px;
    cursor: default;

    .search-icon {
      right: 14px;
      top: 18px;
      fill: $protean-dark-gray;
      position: absolute;
      cursor: pointer;
    }

    .search-input {
      width: 100%;
      height: 32px;
      margin: 0 1px 12px 0;
      padding: 6px 22px 6px 8px;
      border-radius: 4px;
      border: solid 1px #eaeeef;
      background-color: #ffffff;
    }

    .results {
      max-height: 200px;
      overflow-y: auto;

      .no-results {
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-dark-gray;
      }

      .select-item {
        margin: 4px 0px;
        display: flex;

        // align-items: center;
        input {
          margin-top: 1px;
        }

        span {
          margin-left: 10px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
        }
      }
    }

    .buttons {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      button {
        // display: flex;
        width: 48%;
      }
    }
  }
}

.protean-atg-site {
  &.table-content {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;

    .extra {
      display: flex;
      justify-content: flex-end;

      .pagination-size {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }

      .info-tip {
        width: 40px;
        padding: 12px;
      }

      .export-download-dropdown {
        display: flex;
        position: relative;

        button {
          min-width: 200px !important;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
          border: solid 1px #eaeeef !important;
          background-color: #578ef7 !important;

          &.dropdown-toggle:after {
            margin-left: auto;
          }

          &:hover {
            background-color: #3c6ecd !important;
            color: #fff;
          }

          &.reportBtn {
            height: 39px;
            border-radius: 4px !important;
            border: solid 1px #eaeeef;
            background-color: #578ef7;
          }

          &.dropdown-item {
            border: none !important;
            padding-left: 10px;
            padding-right: 0;
            background-color: #578ef7;
            min-width: 198px !important;
          }

          &.dropdown-item:hover {
            background-color: #3c6ecd !important;
            color: #fff;
          }
        }

        .dropdown-menu {
          min-width: 200px !important;
          top: 100%;
          background-color: #578ef7 !important;
          border-radius: 4px !important;
          border: solid 1px #eaeeef;
          margin-top: 0;
        }
      }
    }

    .no-records {
      min-height: 150px;
      align-items: center;
    }

    .wo-table {
      margin-top: 15px;
      border-spacing: 0;

      .wo-tr {
        border-radius: 4px;
        background-color: $protean-white;
        align-items: center;
        user-select: none;
        margin-bottom: 2px;
      }

      .wo-th {
        height: 32px;
        padding-left: 18px;
      }

      .sub-table {
        margin-top: 7px;
        margin-left: 33px;

        .wo-th {
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
        }

        .wo-td {
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;

          .cell {
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
            border-radius: 15px;
            background-color: $protean-white;
            padding: 5px 9px;
            padding: 5px 9px;

            .color-status {
              display: none;
            }

            &.pending {
              display: flex;
              align-items: center;

              .color-status {
                border-radius: 50%;
                display: inline-flex;
                margin-right: 5px;
                width: 10px;
                height: 10px;
                background-color: #efc675;
              }
            }
          }
        }
      }

      .wo-thead {
        .wo-tr {
          .wo-th {
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-dark-gray;
            position: relative;
          }

          .opts {
            width: 33px !important;
            max-width: 110px !important;
            min-width: 110px !important;
          }

          .date-filter {
            display: none;

            &.date {
              display: block;

              .picker {
                position: absolute;
                top: 5px;
                left: 7px;
                display: none;
                z-index: 9;

                .close-picker {
                  display: none;

                  &.show {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 10px;
                  }
                }

                &.true {
                  display: block;

                  .datepicker-block {
                    .icon {
                      display: none;
                    }

                    input {
                      width: 98%;
                      height: 26px;
                      padding-left: 6px;
                      // width: inherit;
                      // height: inherit;
                    }

                    .custom-picker {
                      top: 25px;
                    }
                  }
                }

                .sortNone {
                  display: block;
                  position: absolute;
                  top: 5px;
                  right: 28px;
                }

                .sortUp {
                  display: block;
                  position: absolute;
                  top: 5px;
                  right: 28px;
                  margin-bottom: 0 !important;
                }

                .sortDown {
                  display: block;
                  position: absolute;
                  top: 5px;
                  right: 28px;
                  margin-top: 0 !important;
                }
              }
            }
          }

          .filter-select.date {
            display: none;
          }

          .hide {
            display: none;
          }
        }
      }

      .wo-tbody {
        .status-cell {
          width: 130px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .wo-badge {
            height: 24px;
            align-items: center;
            display: flex;
          }
        }
      }

      .wo-td {
        height: 48px;
        position: relative;
        padding-left: 18px;
        align-items: center;

        .wo-badge-wrapper-oval {
          min-width: 18px;
        }

        .wo-badge {
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          padding: 5px 9px;
          border-radius: 15px;
          background-color: #eaeeef;
        }

        &.opts {
          width: 33px !important;
          max-width: 110px !important;
          min-width: 110px !important;
        }

        .expanded-icon {
          &.true {
            transform: rotate(90deg);
          }

          &.left-margin {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.nested-table {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.protean-link {
  font-size: 14px;
  font-weight: bold;
  color: #3f66d4;
  /* margin-left: 12px; */
}

.protean-atg-commands {
  .widget-tabs {
    width: 30%;
    margin-left: auto;

    .facility-tabs {
      margin-bottom: 0;
    }
  }

  &.facility-fix-alarms > .wo-table {
    .atg-alarms-row {
      margin: 8px 0;
    }

    .wo-td {
      height: unset !important;
      min-height: 40px;

      .alarm-options-btn:last-of-type {
        padding: 0 20px;
        align-items: center;
      }
    }
  }

  .extra {
    align-items: unset !important;
    height: unset !important;

    .pagination-size {
      margin-left: 0 !important;
    }
  }

  &.table-content {
    font-family: Roboto;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;

    &.commands-tablev2 {
      .info-btn.info-icon-tooltip {
        background-color: #f7f9fa;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }
    }

    &.alarms-table {
      .info-btn.info-icon-tooltip {
        background-color: #f7f9fa;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }

      .table-row {
        border-bottom: 1px solid $protean-white;
        padding: 12px 0;
      }

      .wo-th {
        color: $protean-dark-gray !important;

        .search-icon-path {
          fill: #a4a8b1;
        }

        &:last-of-type {
          max-width: 141px !important;
        }

        &:first-of-type {
          max-width: 34px !important;
        }
      }

      .wo-td {
        .tags-icon {
          min-width: 24px;
        }

        word-break: break-word;
        height: unset !important;

        &:last-of-type {
          max-width: 141px !important;
        }

        &:first-of-type {
          max-width: 34px !important;
        }
      }
    }

    .command-actions-group {
      button {
        background-color: $protean-white;
      }

      .generate-btn {
        padding: 0 13px;
        display: flex;
        justify-items: center;
        align-items: center;

        &.active {
          background-color: #86aefb !important;
          height: 32px;
          color: #fff !important;
          cursor: default;
        }

        .loading-indicator {
          animation: rotation 1s linear infinite;
          margin-right: 8px;
        }
      }

      .action-btn {
        height: 32px;
        width: 32px;
      }

      .rerun-placeholder {
        width: 73px;
        height: 32px;
        margin-right: 15px;
      }

      .rerun-btn {
        border-radius: 4px !important;
        font-size: 13px;
        font-weight: bold;
        color: #3a4669;
      }

      .download-btn {
        padding: 10px;
        margin-right: 6px;

        svg {
          fill: $protean-dark-gray;
        }
      }

      .view-btn {
        padding: 5px;
      }
    }

    .no-records {
      min-height: 150px;
      align-items: center;
    }

    .wo-table {
      margin-top: 0;
      border-spacing: 0;

      .wo-tr {
        border-radius: 4px;
        background-color: $protean-white;
        align-items: center;
        user-select: none;
        margin-bottom: 2px;
      }

      .wo-th {
        height: 32px;
        padding-left: 18px;
      }

      .wo-thead {
        .wo-tr {
          .wo-th {
            &.action {
              min-width: 170px !important;
            }

            font-size: 12px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
            position: relative;
          }

          .date-filter {
            display: none;

            &.date {
              display: block;

              .picker {
                position: absolute;
                top: 5px;
                left: 7px;
                display: none;
                z-index: 9;

                .close-picker {
                  display: none;

                  &.show {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 10px;
                  }
                }

                &.true {
                  display: block;

                  .datepicker-block {
                    .icon {
                      display: none;
                    }

                    input {
                      width: 98%;
                      height: 26px;
                      padding-left: 6px;
                    }

                    .custom-picker {
                      top: 25px;
                    }
                  }
                }

                .sortNone {
                  display: block;
                  position: absolute;
                  top: 5px;
                  right: 28px;
                }

                .sortUp {
                  display: block;
                  position: absolute;
                  top: 5px;
                  right: 28px;
                  margin-bottom: 0 !important;
                }

                .sortDown {
                  display: block;
                  position: absolute;
                  top: 5px;
                  right: 28px;
                  margin-top: 0 !important;
                }
              }
            }
          }

          .filter-select.date {
            display: none;
          }

          .hide {
            display: none;
          }
        }
      }

      .wo-tbody {
        .status-cell {
          width: 130px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .wo-badge {
            height: 24px;
            align-items: center;
            display: flex;
          }
        }
      }

      .wo-td {
        height: 48px;
        position: relative;
        padding-left: 18px;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;

        &.action {
          min-width: 170px !important;
        }

        &.acknowledgement {
          justify-content: flex-end !important;
        }

        .expanded-icon {
          &.true {
            transform: rotate(90deg);
          }

          &.left-margin {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

.table-content {
  &.line-item-table {
    word-break: break-word;
  }

  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-dark-gray;

  .extra {
    display: flex;
    justify-content: flex-end;

    .pagination-size {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
    }

    .export-download-dropdown {
      display: flex;
      position: relative;

      button {
        min-width: 200px !important;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        border: solid 1px #eaeeef !important;
        background-color: #578ef7 !important;

        &.dropdown-toggle:after {
          margin-left: auto;
        }

        &:hover {
          background-color: #3c6ecd !important;
          color: #fff;
        }

        &.reportBtn {
          height: 39px;
          border-radius: 4px !important;
          border: solid 1px #eaeeef;
          background-color: #578ef7;
        }

        &.dropdown-item {
          border: none !important;
          padding-left: 10px;
          padding-right: 0;
          background-color: #578ef7;
          min-width: 198px !important;
        }

        &.dropdown-item:hover {
          background-color: #3c6ecd !important;
          color: #fff;
        }
      }

      .dropdown-menu {
        min-width: 200px !important;
        top: 100%;
        background-color: #578ef7 !important;
        border-radius: 4px !important;
        border: solid 1px #eaeeef;
        margin-top: 0;
      }
    }
  }

  .no-records {
    min-height: 150px;
    align-items: center;
  }

  .wo-table {
    border-spacing: 0;

    .wo-tr {
      border-radius: 4px;
      background-color: $protean-white;
      align-items: center;
      user-select: none;
      margin-bottom: 5px;
    }

    .wo-th {
      height: 32px;
      padding-left: 18px;
    }

    .sub-table {
      margin-top: 7px;
      margin-left: 33px;

      .wo-th {
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
      }

      .wo-td {
        &.wo-linetems {
          min-height: auto !important;
        }

        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;

        .line-item-link {
          word-break: break-word;

          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #3f66d4;
        }

        .cell {
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          border-radius: 15px;
          background-color: $protean-white;
          padding: 5px 9px;
          padding: 5px 9px;

          .color-status {
            display: none;
          }

          &.pending {
            display: flex;
            align-items: center;

            .color-status {
              border-radius: 50%;
              display: inline-flex;
              margin-right: 5px;
              width: 10px;
              height: 10px;
              background-color: #efc675;
            }
          }
        }
      }
    }

    .wo-thead {
      .wo-tr {
        .wo-th {
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
          position: relative;
        }

        .date-filter {
          display: none;

          &.date {
            display: block;

            .picker {
              position: absolute;
              top: 5px;
              left: 7px;
              display: none;
              z-index: 9;

              .close-picker {
                display: none;

                &.show {
                  display: block;
                  position: absolute;
                  top: 5px;
                  right: 10px;
                }
              }

              &.true {
                display: block;

                .datepicker-block {
                  .icon {
                    display: none;
                  }

                  input {
                    width: 98%;
                    height: 26px;
                    padding-left: 6px;
                    // width: inherit;
                    // height: inherit;
                  }

                  .custom-picker {
                    top: 25px;

                    &.left {
                      left: unset;
                      right: 0;
                    }
                  }
                }
              }

              .sortNone {
                display: block;
                position: absolute;
                top: 5px;
                right: 28px;
              }

              .sortUp {
                display: block;
                position: absolute;
                top: 5px;
                right: 28px;
                margin-bottom: 0 !important;
              }

              .sortDown {
                display: block;
                position: absolute;
                top: 5px;
                right: 28px;
                margin-top: 0 !important;
              }
            }
          }
        }

        .filter-select.date {
          display: none;
        }

        .hide {
          display: none;
        }
      }
    }

    .wo-tbody {
      .status-cell {
        width: 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .wo-badge {
          height: 24px;
          align-items: center;
          display: flex;
        }
      }

      .wo-tr.inspections {
        background-color: #ffffff;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
        border-bottom: $protean-white solid 1px;

        &.selected {
          background-color: $protean-white;
        }

        .wo-badge {
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          padding: 5px 9px;
          border-radius: 15px;
          background-color: #eaeeef;
        }
      }
    }

    &.wo-track {
      .wo-tbody {
        .wo-tr {
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
          border-radius: 4px;
          background-color: $protean-white !important;
        }
      }
    }

    .wo-td {
      min-height: 48px;
      height: auto;
      position: relative;
      padding-left: 18px;
      align-items: center;

      .wo-badge {
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
        padding: 5px 9px;
        border-radius: 15px;
        background-color: #eaeeef;
      }

      .expanded-icon {
        &.true {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.wo-badge-wrapper-oval {
  min-width: 18px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  background-color: #eaeeef;
}

.wo-badge-status-oval {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;

  &.with-text {
    margin-right: 5px;
  }

  &.wrapped {
    margin-left: 4px;
    margin-top: 4px;
  }

  &.green {
    background-color: #bcd87a;
  }

  &.red {
    background-color: #dc6e6e;
  }

  &.yellow {
    background-color: #efc675;
  }

  &.grey {
    background-color: $protean-gray;
  }
}

.custom-close-btn.V2 {
  top: 27px !important;
}

.details.detailsV2 {
  .m-body {
    height: calc(100% - 70px);
  }
}

.custom-pdf-modal {
  &.cabinet-fix-pdf {
    &.moz-fix {
      .pdf-modal-custom-view {
        height: 100%;
      }
    }

    .m-header {
      margin-top: -50px;
      padding: 8px !important;

      img {
        height: 35px !important;
      }
    }
  }

  .buttons {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .button-block {
      display: flex;
      justify-content: center;
      width: 28%;

      .protean-btn {
        width: -webkit-fill-available;
      }
    }
  }

  &.info {
    .m-header {
      padding: 32px 32px 0 32px;

      h2 {
        margin-bottom: 0;
      }

      margin-bottom: 24px;
    }

    .m-body {
      padding: 0 32px;
      max-height: 875px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 3px;
        height: 256px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #f0f2f4;
      }

      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 10px 10px green;
        border: solid 10px transparent;
      }

      .devider {
        height: 5px;
        background-color: $protean-white;
        margin: 12px 0;
      }

      .main-info {
        width: 100%;
        margin-bottom: 12px;

        &.error {
          .main-info {
            display: none;
          }
        }

        &.equipment {
          .double-col {
            .col-item {
              margin-bottom: 12px;
            }
          }
        }

        &.info,
        &.equipment {
          margin-bottom: 0;

          .info-sub-title {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-black;
          }

          .double-col {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .col-item {
              width: 49%;
              display: flex;
              flex-wrap: wrap;
              min-height: 30px;
              max-height: fit-content;

              .item-title {
                padding-left: 16px;
                display: flex;
                padding-top: 7px;
                padding-bottom: 7px;
                min-height: 29px;
                height: fit-content;
                width: 42%;
                align-items: center;
                border-radius: 4px;
                background-color: $protean-white;
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-dark-gray;
                margin-right: 2%;
                margin-bottom: 2px;
              }

              .item-val {
                padding-left: 16px;
                padding-top: 7px;
                padding-bottom: 7px;
                display: flex;
                min-height: 29px;
                height: fit-content;
                width: 56%;
                align-items: center;
                border-radius: 4px;
                background-color: $protean-white;
                font-family: Roboto;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-black;
                margin-bottom: 2px;

                .badge-val {
                  padding: 5px 9.2px 5px 10px;
                  border-radius: 15px;
                  background-color: #e0e8f1;
                  font-family: Roboto;
                  font-size: 12px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: $protean-gray;
                  margin-right: 12px;
                }
              }
            }
          }
        }

        &.inspection,
        &.testing,
        &.add-info {
          .info-sub-title {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-black;
          }

          .col-item {
            display: flex;
            flex-wrap: wrap;
            min-height: 30px;
            max-height: fit-content;

            .item-title {
              padding-left: 16px;
              padding-top: 7px;
              padding-bottom: 7px;
              display: flex;
              width: 23%;
              align-items: center;
              border-radius: 4px;
              background-color: $protean-white;
              font-family: Roboto;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-dark-gray;
              margin-right: 1%;
              margin-bottom: 2px;
              height: auto;
            }

            .item-val {
              .budge-item {
                border-radius: 15px;
                background-color: #e0e8f1;
                padding: 5px 9px;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-gray;
              }

              .badge-val {
                padding: 5px 9.2px 5px 10px;
                border-radius: 15px;
                background-color: #e0e8f1;
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $protean-gray;
                margin-right: 12px;
              }

              padding-left: 16px;
              padding-top: 7px;
              padding-bottom: 7px;
              height: auto;
              display: flex;
              width: 76%;
              align-items: center;
              border-radius: 4px;
              background-color: $protean-white;
              font-family: Roboto;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-black;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }

  &.status {
    .m-body {
      height: inherit;
      padding: 25px 32px 60px 32px;

      h2 {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-dark-gray;
        margin-bottom: 0;
        margin-top: 14px;
      }

      .comment {
        padding: 16px 0;

        span {
          margin-bottom: 9px;
          font-family: Roboto;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
        }

        textarea {
          resize: none;
          height: 38px;
          width: 100%;
          border-radius: 4px;
          border: solid 1px #eaeeef;
          background-color: #ffffff;
          padding: 8px 12px;

          &.error {
            border: solid 1px #f6a4a4;
          }
        }

        div.error {
          margin-top: 5px;
          margin-left: 16px;
          font-family: Roboto;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #db6969;
        }

        display: flex;
        flex-direction: column;

        .buttons {
          margin-top: 16px;
          display: flex;
          justify-content: flex-start;

          .button-block {
            display: flex;
            justify-content: flex-start;
            width: 28%;

            &:first-of-type {
              display: none;
            }

            .protean-btn {
              margin-right: 16px;
              display: flex;
              justify-content: center;
              width: 122px;
            }
          }
        }
      }

      .info {
        width: 100%;
        // padding: 14px 26px;
        display: flex;
        flex-wrap: wrap;

        .info-title {
          display: flex;
          flex: 16%;
          font-family: Roboto;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          margin: 3px 0;
          display: flex;
          align-items: center;
        }

        .info-value {
          display: flex;
          flex: 70%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
          margin: 3px 0;
          display: flex;
          align-items: center;

          .value-badge {
            .crc {
              &.approved {
                background-color: #bcd87a;
              }

              &.rejected {
                background-color: #dc6e6e;
              }

              display: inline-flex;
              height: 10px;
              width: 10px;
              border-radius: 12px;
              background-color: #efc675;
              margin-right: 8px;
            }

            padding: 5px 10px 5px 8px;
            border-radius: 12px;
            background-color: $protean-white;
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }
        }
      }
    }

    .m-header {
      padding: 32px 32px 0 32px;

      h2 {
        margin-bottom: 0;
      }
    }
  }

  &.details {
    .m-header {
      margin-bottom: 9px;
      justify-content: unset;

      .changeInspection {
        &:disabled {
          opacity: 0.7;
        }

        height: 32px;
        margin-left: 40px;
        padding: 0;
        align-items: center;
        display: flex;
        color: #3a4669;
        justify-content: center;
        width: 168px;

        &:last-of-type {
          margin-left: 16px;
        }

        svg {
          path {
            fill: #3a4669;
          }
        }
      }

      .retry-fail-message {
        margin-right: 16px;
        padding: 16px;
        border-radius: 4px;
        background-color: $protean-white;
        font-size: 12px;
        font-weight: 500;
        color: $protean-dark-gray;
      }

      padding: 32px 32px 0 32px;

      h2 {
        margin-bottom: 0;
      }
    }

    .custom-close-btn {
      top: 10px;

      .custom-close {
        height: 24px;
        fill: $protean-dark-gray;
      }
    }

    .m-body {
      .m-content {
        &.full-image {
          display: flex;
          flex-direction: column;
          position: relative;

          .return-button {
            width: max-content;
            position: absolute;
            top: -52px;
            z-index: 10;
            right: 19px;
            display: flex;
            padding: 9px 18px 9px 13px;
          }

          .arrow {
            width: 42px;
            height: 42px;
            position: absolute;
            // width: fit-content;
            top: 45%;
            cursor: pointer;

            path {
              fill: $protean-gray;
            }

            &-left {
              left: 118px;
            }

            &-right {
              right: 118px;
            }
          }

          path {
            fill: #3a4669;
          }

          .image-block {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              height: 90%;
            }
          }
        }

        height: 100%;
        display: flex;
        flex-direction: row;
        padding-bottom: 18px;

        &.content-v2 {
          .pdf-content {
            width: 66%;
            min-width: 66%;

            .customViewer {
              height: unset !important;
            }

            .full-page-btn {
              top: 24px;
              right: 44px;
              background-color: transparent;
            }
          }

          .details-content {
            .photos {
              padding-top: 22px;

              img {
                width: 22% !important;
              }
            }

            .reports {
              padding: 22px 17px 24px 17px;

              .reports-title-text {
                font-size: 12px;
                font-weight: bold;
                color: $protean-gray;
              }

              .report-single {
                font-size: 13px;
                font-weight: 500;
                color: $protean-dark-gray;
                padding: 4px 2px;
                display: flex;
                line-height: normal;
                width: 100%;
                align-items: center;

                &:first-of-type {
                  margin-top: 4px;
                }

                &:hover,
                &.active {
                  background-color: $protean-white;
                }

                .check {
                  margin-left: 4px;

                  path:last-of-type {
                    fill: $protean-dark-gray;
                  }
                }

                .primary {
                  line-height: normal;
                  font-size: 12px;
                  font-weight: 500;
                  color: $protean-gray;
                  padding: 2px 8px;
                  border-radius: 4px;
                  background-color: $protean-white;
                  margin-left: 8px;
                }
              }
            }

            .comments {
              padding: 22px 17px 24px 17px;

              .single-comment {
                margin: 8px 8px 0 8px;
                display: flex;
                font-size: 14px;
                font-weight: normal;
                color: $protean-black;

                .number {
                  width: 19px;
                  text-align: left;
                  margin-right: 8px;
                }
              }
            }

            .logs {
              padding: 24px 23px;

              .logs-title {
                font-family: Roboto;
                font-size: 15px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-black;
                margin-bottom: 17px;
                display: flex;
              }

              .log {
                font-family: Roboto;
                font-size: 15px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-black;

                .title {
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;

                  .status > .status-badge {
                    display: flex;

                    .crc {
                      &.approved {
                        background-color: #bcd87a;
                      }

                      &.rejected {
                        background-color: #dc6e6e;
                      }

                      display: inline-flex;
                      height: 10px;
                      width: 10px;
                      border-radius: 12px;
                      background-color: #efc675;
                      margin-right: 8px;
                    }

                    padding: 5px 10px 5px 8px;
                    border-radius: 12px;
                    background-color: $protean-white;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $protean-gray;
                    margin-right: 8px;
                  }

                  .date {
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $protean-dark-gray;
                  }

                  .author {
                    margin-left: auto;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $protean-dark-gray;
                  }
                }

                .text {
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $protean-black;
                  margin-bottom: 18px;

                  &.status-description {
                    margin-bottom: 8px;
                  }
                }
              }
            }

            .item-trigger {
              display: flex;
              align-items: center;
              border: none;
              background-color: transparent;
              font-size: 15px;
              font-weight: 500;
              color: $protean-black;

              .many-indicator {
                margin-left: 8px;
                transform: translateY(-6px);
                border-radius: 50%;
                width: 6px;
                height: 6px;
                background-color: #a5cd48;
              }
            }

            width: 33%;
            min-width: 0;

            .info {
              padding: 0 29px;

              .info-title {
                min-width: 99px;
              }

              .info-value {
                font-size: 14px;
                font-weight: normal;
                color: $protean-black;

                .map-link {
                  color: #3c6ecd;
                }

                span {
                  font-size: 14px;
                  font-weight: normal;
                  color: $protean-dark-gray;
                }
              }
            }

            .facility-title {
              .title {
                margin-bottom: 6px;
              }

              padding: 0 25px 6px 25px;
              display: flex;
              background-color: #fff;
              position: sticky;
              top: 0;
              z-index: 9;

              .value-badge {
                min-width: fit-content;
                height: fit-content;
                margin-left: 12px;

                .crc {
                  &.approved {
                    background-color: #bcd87a;
                  }

                  &.rejected {
                    background-color: #dc6e6e;
                  }

                  display: inline-flex;
                  height: 10px;
                  width: 10px;
                  border-radius: 12px;
                  background-color: #efc675;
                  margin-right: 8px;
                }

                padding: 5px 10px 5px 8px;
                border-radius: 12px;
                background-color: $protean-white;
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-gray;
              }

              font-size: 14px;
              font-weight: 500;
              color: $protean-black;
            }
          }
        }

        .details-content {
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 3px;
            height: 56px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 3.5px;
            background-color: #f0f2f4;
          }

          // display: flex;
          width: 50%;
          min-width: 580px;
          padding: 0 7px;
          flex-direction: column;

          .no-data {
            display: flex;
            width: 100%;
            margin-top: 10px;
            justify-content: center;
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }

          .info {
            height: 127px;
            width: 100%;
            padding: 14px 26px;
            display: flex;
            flex-wrap: wrap;

            .info-title {
              display: flex;
              flex: 16%;
              font-family: Roboto;
              font-size: 13px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-gray;
              margin: 3px 0;
              display: flex;
              align-items: center;
            }

            .info-value {
              display: flex;
              flex: 70%;
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-black;
              margin: 3px 0;
              display: flex;
              align-items: center;

              .value-badge {
                .crc {
                  &.approved {
                    background-color: #bcd87a;
                  }

                  &.rejected {
                    background-color: #dc6e6e;
                  }

                  display: inline-flex;
                  height: 10px;
                  width: 10px;
                  border-radius: 12px;
                  background-color: #efc675;
                  margin-right: 8px;
                }

                padding: 5px 10px 5px 8px;
                border-radius: 12px;
                background-color: $protean-white;
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-gray;
              }
            }
          }

          .photos {
            padding: 0 17px 24px 17px;

            .Collapsible__trigger {
              width: 100%;
            }

            .Collapsible__contentInner {
              display: flex;
              flex-wrap: wrap;

              img,
              .image-loader {
                &:nth-of-type(5n + 5) {
                  margin-right: 0;
                }

                border-radius: 4px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                cursor: pointer;
                object-fit: cover;
                width: 93px;
                height: 120px;
                margin: 16px 6px 0 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-gray;
              }
            }
          }

          .comment {
            height: 187px;
            padding: 24px 9px;

            span {
              margin-bottom: 9px;
              font-family: Roboto;
              font-size: 13px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-gray;
            }

            textarea {
              resize: none;
              height: 38px;
              width: 100%;
              border-radius: 4px;
              border: solid 1px #eaeeef;
              background-color: #ffffff;
              padding: 8px 12px;

              &.error {
                border: solid 1px #f6a4a4;
              }
            }

            div.error {
              margin-top: 5px;
              margin-left: 16px;
              font-family: Roboto;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #db6969;
            }

            display: flex;
            flex-direction: column;
          }

          .logs {
            padding: 24px 23px;

            .logs-title {
              font-family: Roboto;
              font-size: 15px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-black;
              margin-bottom: 17px;
              display: flex;
            }

            .log {
              font-family: Roboto;
              font-size: 15px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-black;
              margin-top: 16px;

              .dispatch-comment-title {
                font-size: 14px;
                font-weight: 500;
                color: $protean-gray;
                margin-top: -10px;
              }

              .title {
                display: flex;
                align-items: baseline;
                margin-bottom: 8px;

                .status > .status-badge {
                  .crc {
                    &.approved,
                    &.created,
                    &.dispatched {
                      background-color: #bfe85f;
                    }

                    &.rejected {
                      background-color: #dc6e6e;
                    }

                    display: inline-flex;
                    height: 10px;
                    width: 10px;
                    border-radius: 12px;
                    background-color: #efc675;
                    margin-right: 8px;
                  }

                  padding: 5px 10px 5px 8px;
                  border-radius: 12px;
                  background-color: $protean-white;
                  font-family: Roboto;
                  font-size: 12px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $protean-gray;
                  margin-right: 8px;
                }

                .date {
                  font-family: Roboto;
                  font-size: 13px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $protean-dark-gray;
                }

                .author {
                  margin-left: auto;
                  font-family: Roboto;
                  font-size: 13px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $protean-dark-gray;
                }
              }

              .text {
                font-family: Roboto;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-black;
                margin-bottom: 18px;
              }
            }
          }

          .devider {
            width: 100%;
            height: 5px;
            background-color: $protean-white;
          }
        }

        .pdf-content {
          display: flex;
          flex-direction: column;
          width: 50%;
          min-width: 576px;
          padding: 0 7px;
          overflow-y: hidden;

          .customViewer {
            overflow-y: auto;
            height: 800px !important;

            &.fPage {
              height: 861px !important;
            }
          }

          .full-page-btn {
            position: absolute;
            top: 213px;
            right: 20px;
            padding: 0;
            z-index: 999;
          }

          .buttons {
            display: flex;
            justify-content: space-between;
            margin-bottom: 9px;

            svg {
              fill: #3a4669;
            }

            button:disabled {
              svg {
                g {
                  path {
                    fill: $protean-gray;
                  }
                }
              }
            }
          }

          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .custom-close-btn {
    &.info {
      top: 32px;
    }

    &.atg-report-pdf {
      margin-right: 25px;
      background-color: $protean-white;
      border-radius: 5px;
    }

    top: 22px;

    .custom-close {
      height: 24px;
      fill: $protean-dark-gray;
    }
  }

  .m-header {
    padding: 14px 71px 14px 39px;
    display: flex;
    justify-content: space-between;

    img {
      height: 43px;
    }
  }

  .m-body {
    height: 882px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.map-fix {
  width: 176px;
  height: 116px;
  position: absolute;
  z-index: 9999;

  // opacity: 0.5;
  .map-loader {
    background-color: rgba(255, 255, 255, 0.5);
    height: 116px;
    min-height: 116px;
  }
}

.pdf-custom-viewer {
  width: 100%;
  height: 100%;
  background-color: #1d1d1d;

  .react-pdf__Page {
    background-color: #1d1d1d;
    display: flex;
    justify-content: center;
  }

  .react-pdf__Page__svg {
    background-color: inherit !important;
    margin-top: 10px;
    width: 100% !important;
    height: auto !important;
    display: flex !important;
    justify-content: center;
  }

  svg {
    background-color: white;
    width: 99%;
    height: auto;
  }

  .react-pdf__Page__textContent {
    display: none;
  }
}

.pagesBlock {
  width: 49%;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: row;

  &.active {
    width: 100%;
  }

  .pages {
    background-color: $protean-white;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: unset;
    letter-spacing: normal;
    text-align: center;
    color: #3a4669;
    display: flex;
    align-items: center;
  }

  .pageButton {
    &.prev {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.next {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.rpv-default-layout-body {
  overflow: scroll !important;
}

.pdf-modal-custom-view {
  height: -webkit-fill-available;
  overflow: auto;

  .rpv-default-layout-main {
    .rpv-default-layout-sidebar {
      display: none;
    }

    overflow: auto;
  }

  /* iframe { */
  /*   img { */
  /*     justify-content: center; */
  /*   } */
  /* } */
}

.cabinet-fix-pdf {
  .pdf-protean-viewer {
    width: 100%;
    height: 100%;

    .rpv-core__viewer {
      width: 100%;
      height: 100%;
    }
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 28px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(87, 142, 247, 0.3);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  max-height: 665px;

  h1 {
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: #3a4669;
  }

  svg {
    width: 80%;
    height: auto;
    margin: auto;
  }

  .protean-btn {
    width: fit-content;
    display: flex;
    justify-self: center;
    margin: 20px auto 0;
  }
}

.nonAuthBlock {
  width: 100%;
  height: 100vh;
  padding: 50px;
  background-color: #283049;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .error-block {
    margin: auto;
    width: 60%;
  }
}

.centerItems {
  display: flex;
  justify-content: center;
  align-items: center;
}

.protean-workorder {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(87, 142, 247, 0.3);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  padding: 0;

  .item {
    display: flex;
    width: 100%;
    margin-bottom: 8px;

    .item-title {
      display: flex;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
      width: 140px;
      margin-right: 2px;
      padding: 8px 16px;
      border-radius: 4px;
      background-color: $protean-white;
    }

    .item-value {
      display: flex;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      padding: 8px 16px;
      width: -webkit-fill-available;
      border-radius: 4px;
      background-color: $protean-white;
    }
  }

  .workorder-block {
    border-bottom: solid $protean-white 5px;

    &:last-of-type {
      border-bottom: none;
    }

    .reopen-content {
      width: 420px;

      .reopen-comment {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }

      .reopen-header {
        margin-bottom: 8px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $protean-dark-gray;

        .reopen-details {
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
        }
      }
    }

    padding: 32px 33px;

    .switch {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .edit-btn {
      padding: 0;
    }

    .view-details {
      height: 39px;
    }

    .workorder-block-header {
      display: flex;
      align-items: center;
      margin-bottom: 44px;

      .actions {
        margin-left: auto;
        display: flex;
        flex-direction: row;

        .dropdown-menu {
          right: 0;
          left: unset;
          width: 534px;
        }

        div > div > #keys-map {
          &.keys-map {
            //height: 220px;
            //padding: 24px;
            padding: 8px 16px 0 16px;
            border-radius: 4px;
            background-color: $protean-white;
            display: flex;
            flex-direction: row;

            .column {
              width: 50%;

              .keys-item {
                flex: 0.5;
                display: flex;
                margin-bottom: 8px;
                height: 22px;

                .keys-title {
                  height: 22px;
                  min-width: 90px;
                  border-radius: 4px;
                  background-color: #ffffff;
                  font-size: 12px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: $protean-dark-gray;
                  margin-right: 8px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  &.true {
                    background-color: transparent !important;
                  }
                }

                .keys-value {
                  height: 22px;
                  font-family: Roboto;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $protean-dark-gray;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }

        .protean-btn {
          height: 40px;
          width: 40px;
          display: flex;
          padding: 0;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .group-comment {
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $protean-dark-gray;
      margin-bottom: 16px;

      span {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $protean-dark-gray;
        max-width: 1000px;
        display: block;
      }
    }

    .group-users {
      align-items: center;
      font-size: 14px;
      font-family: Roboto;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;

      &.edit {
        @media screen and (max-width: 1560px) {
          flex-direction: column;

          .user-assign {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 8px;
            width: -webkit-fill-available;

            &.type {
              .user-assign-title {
                width: 75px;
              }
            }

            .user-assign-title {
              width: 100px;
            }

            .user-select {
              width: 190px;
            }
          }
        }
      }

      .user-assign {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.type {
          .user-select {
            width: 150px;
          }
        }
      }

      .user-select {
        width: 290px;
      }

      .user-title {
        font-weight: bold;
        color: $protean-dark-gray;
      }

      .highlight {
        margin-left: 8px;
        font-weight: normal;
        color: $protean-black;
      }
    }

    .actions-cell {
      g {
        fill: $protean-dark-gray;
      }
    }

    .wo-title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      display: flex;
      align-items: center;

      &-main {
        font-size: 24px;
      }

      .wo-status-budge {
        padding: 4px 12px;
        border-radius: 15px;
        background-color: #e0e8f1;
        margin-right: 12px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #3a4669;
      }
    }

    .create-group {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: $protean-gray;
      display: flex;
      align-items: center;

      .user-select {
        width: 292px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;

        &.reassign {
          width: 300px;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      flex: 60;
      margin-right: 24px;
    }

    .detail-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .item {
        width: 32%;
      }

      margin-bottom: 32px;
    }

    .single-wo {
      .wo-tbody {
        .wo-tr {
          .wo-td {
            min-height: 48px;
            height: auto;
          }
        }
      }
    }

    &.logs {
      .block-title {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .Collapsible__contentInner {
        margin-top: 32px;
        position: relative;
      }

      display: flex;
      flex-direction: column;
      position: relative;

      .log-item {
        position: relative;
        margin-bottom: 32px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .log-circle {
          position: absolute;
          border-radius: 50%;
          left: -20px;
          top: 1px;
          width: 13px;
          height: 13px;
          border: solid 2px #7a86a9;
          background-color: #ffffff;
        }

        margin-left: 50px;
        width: auto;
        display: flex;
        flex-direction: column;

        .log-header {
          display: flex;
          font-family: Roboto;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;

          .log-delimiter {
            margin: 0 5px;
          }
        }

        .log-comment {
          margin-top: 8px;
          display: flex;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
        }
      }
    }
  }
}

.select-loading-indicator {
  display: flex;
  align-items: center;
  margin-right: 10px;

  .spinner-border {
    width: 1rem;
    height: 1rem;
    color: $protean-gray;
  }
}

.protean-atg {
  padding: 16px;

  .title-block {
    padding: 16px;

    h1 {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      margin-bottom: 12px;
    }

    span {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
    }
  }

  .atg-content {
    .terminal-from {
      width: 350px;

      .terminal-form-btns {
        display: flex;
        justify-content: space-between;

        button {
          width: 47%;
        }
      }

      .terminal-field {
        margin-bottom: 20px;

        .terminal-form-label {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: bold;
          color: #24272a;
        }
      }
    }

    padding: 16px;

    .fetch-filters {
      &.false {
        .Collapsible {
          .Collapsible__contentOuter {
            overflow: hidden;
          }
        }
      }

      &.true {
        .Collapsible {
          .Collapsible__contentOuter {
            overflow: visible !important;
          }
        }
      }

      .filter-date {
        & > span {
          display: block;
          margin-bottom: 8px;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
        }

        .protean-select {
          .select-options {
            left: 0;
            width: calc(100% - 8px);
          }

          .selected-text {
            margin-left: 0;
          }
        }
      }

      .fetch-filter {
        span:first-of-type {
          display: block;
          margin-bottom: 8px;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
        }

        .clear-button {
          position: absolute;
          right: -32px;
          top: 23px;
          height: 38px;
          width: 38px;

          svg {
            height: 23px;
            width: 23px;
          }
        }
      }
    }
    .exportbtn-ddl {
      display: inline-block;
    }
  }
}

.command-group {
  .dropdown-toggle:after {
    margin-left: auto;
  }

  .command-btn :hover {
    background-color: #f0f2f4 !important;
    color: #3a4669;
  }

  .command-btn {
    height: 39px;
    border-radius: 4px !important;
    border: solid 1px #eaeeef;
    min-width: 200px;
    font-size: 14px;
    font-weight: 500;
    color: #3a4669;
    border: none !important;
    background-color: $protean-white !important;
  }

  .info-btn {
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
    min-height: 40px !important;
    margin-left: 6px !important;
    padding: 12px !important;
  }

  .execute-btn {
    margin-left: 12px !important;
    width: 120px;
    min-width: 120px;
    height: 39px;
    padding: 0 !important;
    font-family: Roboto;
    font-size: 13px;
    font-weight: bold;
    text-align: center !important;
  }

  .dropdown-item {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    border: none !important;
    padding-left: 10px;
    padding-right: 0;
    background-color: $protean-white !important;
    min-width: 198px !important;
    color: #3a4669;

    .code {
      padding: 2px 8px;
      border-radius: 4px;
      background-color: #e0e8f1;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3a4669;
      margin-right: 9px;
    }
  }

  .command-btn :hover {
    background-color: #f0f2f4 !important;
    color: #3a4669;
  }

  .command-btn {
    height: 39px;
    border-radius: 4px !important;
    border: solid 1px #eaeeef;
    min-width: 200px;
    font-size: 14px;
    font-weight: 500;
    color: #3a4669;
    border: none !important;
    background-color: $protean-white !important;
  }

  .dropdown-item:hover {
    background-color: #f0f2f4 !important;
    color: #3a4669;
  }

  .dropdown-menu {
    min-width: 414px !important;
  }
}

.category-dropdown {
  &.alarms {
    width: 170px;

    .dropdown-button {
      min-width: 170px;
    }

    .dropdown-menu {
      min-width: 170px !important;
    }
  }

  .dropdown-button {
    height: 40px;
    border-radius: 4px !important;
    border: solid 1px #eaeeef;
    min-width: 220px;
    font-size: 13px;
    font-weight: 500;
    color: #3a4669;
    border: none !important;
    background-color: $protean-white !important;
    text-align: left;
    display: flex !important;
  }

  .dropdown-menu {
    min-width: 250px !important;
  }

  .dropdown-toggle:after {
    margin-left: auto;
  }
}

.dropdown-menu-atg-fix {
  min-width: 414px !important;
  top: 100%;
  border: solid 1px #eaeeef;
  margin-top: 3px;
  padding-top: 10px;
  padding-left: 8px;
  border-radius: 4px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: $protean-white;
  max-height: 345px;

  .command-block {
    overflow-y: scroll;
    max-height: 285px;

    .dropdown-item {
      padding-top: 6px !important;
      padding-bottom: 6px !important;

      .atg-dropdown-span {
        &.code-span {
          margin-right: 8px;
          font-size: 13px;
          font-weight: normal;
          line-height: 1.23;
          color: #3a4669;
        }

        font-family: Roboto;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #3a4669;
      }

      .command-checkbox {
        margin-right: 8px;
      }
    }
  }

  .search-input {
    &::placeholder {
      font-size: 13px;
      font-weight: 500;
      color: $protean-gray;
    }

    width: 320px;
    margin-left: 8px;
    padding: 6px;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    font-family: Roboto;
    background: $protean-white url('../images/search.svg') no-repeat 288px 4px;
  }

  .cross-icon {
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 56px;
  }

  &::-webkit-scrollbar-track {
    background-color: $protean-white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #e5e8ec;
  }
}

.atg-dropdown {
  display: flex;
  position: relative;

  .dropdown-menu {
    top: 100%;
    border: solid 1px #eaeeef;
    margin-top: 3px;
    padding-top: 10px;
    padding-left: 8px;
    border-radius: 4px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: $protean-white;
    max-height: 345px;

    .command-block {
      overflow-y: scroll;
      max-height: 285px;
    }

    .search-input {
      width: 320px;
      margin-left: 8px;
      padding: 6px;
      border-radius: 4px;
      border: solid 1px #eaeeef;
      font-family: Roboto;
      background: $protean-white url('../images/search.svg') no-repeat 288px 4px;
    }

    .cross-icon {
      cursor: pointer;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 56px;
    }

    &::-webkit-scrollbar-track {
      background-color: $protean-white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3.5px;
      background-color: #e5e8ec;
    }
  }

  .atg-dropdown-span {
    font-family: Roboto;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #3a4669;
  }

  .dropdown-item {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .command-checkbox {
    margin-right: 8px;
  }
}

#portal-select-hack {
  z-index: 9999;
}

.SingleDatePicker_picker {
  top: 38px !important;
}

.SingleDatePickerInput {
  div.DateInput {
    .DateInput_fang.DateInput_fang_1 {
      top: 27px !important;
    }

    .DateInput_input {
      height: 27px;
    }
  }
}

.setup-modal {
  .current-wrap {
    padding-right: 27px;
  }

  .setup-form {
    padding-right: 27px;
  }

  .setup-current {
    max-height: 210px;
    overflow-y: auto;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .main-block {
    padding-top: 32px;
    padding-left: 32px;
  }

  .setup-header {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
  }

  .setup-sub-header {
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    margin-top: 15px;
  }

  .setup-value {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;
    margin-top: 10px;
  }

  .setup-form-text-input {
    width: 100%;
    height: 38px;
    padding-left: 8px;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    background-color: #ffffff;
  }

  .setup-wrapper {
    width: 100%;
    min-height: 38px;
    overflow-y: auto;
    max-height: 290px;
  }

  .setup-input-wrapper {
    width: 80%;
    min-height: 38px;
  }

  .setup-form-input-error {
    background-color: #fce4e4;
    border: 1px solid #cc0033;
    outline: none;
  }

  .setup-error-text {
    color: #cc0033;
  }

  .nested {
    padding: 0 !important;
  }

  .setup-form-label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;
  }

  .separator {
    width: 95%;
    height: 2px;
    background-color: $protean-white;
  }

  .populate-button {
    height: 36px !important;
    width: 120px !important;
    margin-left: 25px;
    position: absolute;
    right: 30px;
  }

  .setup-choice-item {
    margin-top: 8px;
    max-width: 75%;
  }
}

.setup-category-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $protean-black;
}

.atg-modal-btn-group {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  padding-right: 32px;

  button {
    min-width: 120px;
    height: 39px;
    border-radius: 4px;
  }

  .cancel-btn {
    background-color: $protean-white;
    margin-right: 12px;
    border: solid 1px $protean-white !important;
    border-radius: 4px !important;
  }

  .cancel-btn:focus {
    outline: 0;
  }

  .cancel-btn:hover {
    background-color: #f0f2f4;
  }

  .run-btn {
    background-color: #578ef7;
  }

  .run-btn:hover {
    background-color: #3f66d4;
  }

  .run-btn:disabled {
    background-color: gray;
  }

  .setup-run-btn {
    min-width: 170px !important;
  }
}

.command-modal {
  tbody {
    max-height: 400px;
    overflow-y: auto;
  }

  .command-header {
    padding-top: 32px;
    padding-left: 27px;
  }

  .command-modal-hint {
    padding-left: 27px;
    margin-top: 15px;
    font-family: Roboto;
    font-size: 15px;
    color: $protean-black;
  }

  .modal-tooltip {
    z-index: 999999;
  }

  .command-spinner {
    width: 12px;
    height: 12px;
  }

  .command-info-icon {
    margin-left: 12px;
  }

  .command-modal-table {
    width: 100%;
    margin-top: 15px;
    padding-left: 32px;
    margin-bottom: 12px;
  }

  .modal-btn {
    margin-left: 32px;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .table-item {
    .item-cell {
      font-family: Roboto;
      font-size: 14px;
      color: $protean-dark-gray;
    }

    .result-item-cell {
      height: 32px;
    }

    .header-cell {
      font-family: Roboto;
      font-size: 13px;
      color: $protean-gray;
    }

    .site-cell {
      width: 270px;
    }

    .command-cell {
      width: 270px;
    }

    .status-cell {
      width: 75px;

      * {
        margin-left: 10px;
      }
    }

    .action-cell {
      width: 100px;
    }

    .action-btn {
      height: 30px;
      width: 30px;
    }

    .download-btn {
      padding: 3px;
    }

    .view-btn {
      padding: 4px;
      margin-right: 8px;
    }
  }

  .command-item {
    height: 40px;

    .site-cell {
      width: auto;
    }

    .command-cell {
      width: 100px;
    }
  }

  .disabled-action {
    .icon {
      opacity: 0.3;
    }

    .spinner {
      position: absolute;
      left: 6px;
      top: 6px;
    }
  }
}

.custom-pdf-modal.reopen {
  height: 374px;

  .m-header {
    padding: 14px 71px 14px 32px;
  }

  .m-body {
    height: unset;
    padding: 0 32px 32px 32px;

    .info {
      font-family: Roboto;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      margin-bottom: 34px;
    }

    .field-title {
      font-family: Roboto;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-gray;
    }

    textarea {
      border-radius: 4px;
      border: solid 1px #eaeeef;
      background-color: #ffffff;
      resize: none;
      height: 133px !important;
    }

    .comment-mention {
      height: 133px !important;

      &:focus-visible {
        outline: transparent !important;
      }

      .comment-mention__highlighter {
        overflow: auto;
        height: 133px;
      }

      .comment-mention__suggestions {
        background-color: $protean-white !important;
        padding: 6px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;

        .comment-mention__suggestions__list {
          .comment-mention__suggestions__item {
            padding: 5px 10px;

            &.comment-mention__suggestions__item--focused {
              background-color: #eaeeef !important;
            }
          }
        }
      }

      .comment-mention__control {
        height: 133px;

        .comment-mention__input {
          border-radius: 4px;
          border: solid 1px #eaeeef;
          height: 133px !important;
          overflow: auto !important;

          &:focus-visible {
            //outline: 1px solid #eaeeef !important;
            outline: none !important;
          }
        }

        outline: transparent !important;
      }
    }
  }

  .m-footer {
    padding: 0 32px;
    display: flex;
    justify-content: flex-end;
  }
}

.protean-lineitems {
  padding: 32px;

  h1.title {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    margin-bottom: 13px;
  }

  h1.description {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;
    margin-bottom: 32px;
  }

  .switch {
    div {
      display: flex;
    }

    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    display: flex;
    flex-direction: row;
    height: 39px;
    display: flex;
    align-items: center;
  }

  .fetch-filters {
    .toggle-filters-btn {
      height: 32px;
      padding: 5px 19px;
    }

    &.false {
      .Collapsible {
        .Collapsible__contentOuter {
          overflow: hidden;
        }
      }
    }

    &.true {
      .Collapsible {
        .Collapsible__contentOuter {
          overflow: visible !important;
        }
      }
    }

    .single-input {
      border-color: hsl(0, 0%, 80%);
    }

    .filter-date {
      & > span {
        display: block;
        margin-bottom: 8px;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
      }

      .protean-select {
        .select-options {
          left: 0;
          width: calc(100% - 8px);
        }

        .selected-text {
          margin-left: 0;
        }
      }
    }

    .col-b {
      width: fit-content;
      flex-basis: unset;
    }

    .col-s {
      max-width: 280px;
      flex-basis: unset;
    }

    .fetch-filter {
      span:first-of-type {
        display: block;
        margin-bottom: 8px;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
      }

      .clear-button {
        position: absolute;
        right: -32px;
        top: 23px;
        height: 38px;
        width: 38px;

        svg {
          height: 23px;
          width: 23px;
        }
      }
    }
  }

  .wo-table.wo-track {
    .wo-tbody {
      .wo-tr {
        .wo-td {
          display: flex;
          word-break: break-word;

          &:last-of-type {
            width: 16px !important;
            padding: 0;
            flex: none !important;
          }
        }

        .line-item-link {
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #3f66d4;
        }

        background-color: transparent !important;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;

        .protean-badge {
          color: $protean-gray;
        }
      }
    }
  }
}

.fix-table-padding {
  padding-bottom: 130px;
}

.fix-table-padding-date {
  padding-bottom: 300px;
}

.protean-base-item {
  padding: 0;

  .devider {
    height: 5px;
    width: 100%;
    background-color: $protean-white;
  }

  .set_due_date {
    display: flex;
    flex-direction: column;
    padding: 24px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    color: $protean-dark-gray;

    .date-block {
      width: 310px;
      display: flex;
      flex-direction: row;

      .protean-btn {
        margin-left: 8px;
      }
    }
  }

  .assign_block {
    display: flex;
    flex-direction: row;
    padding: 24px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    color: $protean-dark-gray;

    .assign_type {
      width: 169px;
      height: 50px;
      margin-right: 8px;

      .select-type {
        .selected-text {
          margin: 0;
          font-family: Roboto;
          font-size: 14px;
          color: $protean-black;
        }
      }
    }

    .assign_user {
      display: flex;
      flex-direction: row;
      width: 310px;

      .select-user {
        width: 200px;
        margin-right: 8px;
      }
    }
  }

  .item-block {
    padding: 32px;

    .item-block-header {
      display: flex;
      flex-direction: row;
    }

    &.tabs {
      padding: 22px 16px 0px;
    }

    .change-status-dropdown {
      position: absolute;
      right: 32px;
      top: 32px;
    }

    &.files {
      padding-top: 0;

      .block-title {
        display: none;
      }
    }

    &.edit {
      display: flex;
      flex-direction: row;

      .due-date {
        height: auto;
        display: flex;
        flex: 35%;
        flex-direction: column;
      }

      .assign {
        height: auto;
        display: flex;
        flex: 65%;
        flex-direction: column;

        .type {
          display: flex;
          flex: 40%;
          flex-direction: column;
          width: 310px;
          margin-right: 16px;

          .protean-select {
            .selected-text {
              margin: 0;
            }

            .select-options {
              width: 100%;
              left: 0;
            }
          }
        }

        .user {
          display: flex;
          flex-direction: column;
          flex: 60%;
          position: relative;

          #assign {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 38px;
          }
        }
      }
    }

    &.comments-block {
      padding-top: 0;

      .add-comment {
        position: relative;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;

        span {
          width: 100%;
          display: flex;
        }

        input {
          height: 38px;
          padding-left: 12px;
          font-size: 14px;
          width: 100%;
          border-radius: 4px;
          border: solid 1px #eaeeef;
          background-color: #ffffff;
        }

        button {
          position: absolute;
          top: 15px;

          &#submit {
            right: 0;
            height: 38px;
          }

          &.attach {
            top: 18px;
            right: 71px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .comment {
        margin-bottom: 19px;

        &.sub {
          margin-left: 32px;
          margin-top: 19px;
          margin-bottom: 0;
        }

        .comment-user {
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
          margin-bottom: 4px;

          .comment-issue {
            padding: 7px 12px;
            border-radius: 4px;
            background-color: $protean-white;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-black;
            margin-left: 8px;
          }
        }

        .comment-text {
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
          margin-bottom: 8px;
          line-height: 20px;

          .mention-str {
            padding: 5px 12px;
            border-radius: 15px;
            background-color: $protean-white;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #3f66d4;
          }
        }

        .comment-file {
          display: flex;
          flex-direction: column;

          button {
            width: fit-content;
            appearance: none;
            border: none;
            padding: 2px 4px;
            border-radius: 4px;
            background-color: $protean-white;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-black;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
          }
        }

        .comment-date {
          font-family: Roboto;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;

          .reply-btn {
            font-size: 13px;
            font-weight: 500;
            color: $protean-gray;
            margin-left: 16px;

            &:hover {
              color: $protean-black;
            }
          }
        }

        .reply-comment {
          margin-top: 8px;
          display: flex;
          flex-direction: row;

          .comment-mention {
            width: 55%;

            .comment-mention__suggestions {
              background-color: $protean-white !important;
              max-height: 80px !important;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 3px;
                height: 56px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 3.5px;
                background-color: #f0f2f4;
              }

              ul {
                max-height: 80px !important;
              }

              padding: 6px;
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-black;

              .comment-mention__suggestions__list {
                .comment-mention__suggestions__item {
                  padding: 5px 10px;

                  &.comment-mention__suggestions__item--focused {
                    background-color: #eaeeef !important;
                  }
                }
              }
            }

            .comment-mention__input {
              border-radius: 4px;
              border: solid 1px #eaeeef;
              background-color: #ffffff;
              padding-left: 16px;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-black;
              height: 38px;
            }
          }

          .protean-default-input {
            width: 55%;
          }

          .protean-btn {
            width: 120px;

            &:nth-of-type(1) {
              margin: 0 2px;
            }
          }
        }
      }
    }

    .log-item {
      position: relative;
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .log-circle {
        position: absolute;
        border-radius: 50%;
        left: -20px;
        top: 1px;
        width: 13px;
        height: 13px;
        border: solid 2px #7a86a9;
        background-color: #ffffff;
      }

      margin-left: 100px;
      width: auto;
      display: flex;
      flex-direction: column;

      .log-header {
        display: flex;
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;

        .log-delimiter {
          margin: 0 5px;
        }
      }

      .log-comment {
        margin-top: 8px;
        display: flex;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-dark-gray;
      }
    }

    &.logs {
      &.group {
        padding-top: 32px;

        &:first-child {
          margin-top: 32px;
        }

        .Collapsible__contentInner {
          position: relative;

          &:first-child {
            margin-top: 32px;
          }

          .log-item {
            margin-left: 50px;
          }

          .logs-list {
            max-height: 235px;
            overflow-y: auto;
          }
        }
      }

      padding-top: 0;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .issue-photo-cell {
      width: 48px;
      height: 48px;
    }

    .block-title {
      display: flex;
      align-items: center;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      margin-bottom: 24px;

      .issue-badge {
        padding: 4px 12px;
        border-radius: 15px;
        background-color: #e0e8f1;
        margin-right: 12px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #3a4669;
        margin-left: 8px;
      }

      &.main {
        font-size: 24px;
        margin-bottom: 24px;
      }

      &.collapsible-title {
        cursor: pointer;
        width: fit-content;
      }

      &.mutted-title {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-dark-gray;
        margin-bottom: 13px;
      }
    }

    .double-col {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &.group-single {
        .col-item {
          width: 35%;

          .item-title {
            width: 30%;
          }

          .item-val {
            width: 59%;
          }
        }
      }

      .col-item {
        width: 49%;
        display: flex;
        flex-wrap: wrap;
        min-height: 30px;
        max-height: fit-content;

        .item-title {
          padding-left: 16px;
          display: flex;
          padding-top: 7px;
          padding-bottom: 7px;
          min-height: 29px;
          height: fit-content;
          width: 20%;
          align-items: center;
          border-radius: 4px;
          background-color: $protean-white;
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
          margin-right: 2%;
          margin-bottom: 2px;
        }

        .item-val {
          padding-left: 16px;
          padding-top: 7px;
          padding-bottom: 7px;
          display: flex;
          min-height: 29px;
          height: fit-content;
          width: 78%;
          align-items: center;
          border-radius: 4px;
          background-color: $protean-white;
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
          margin-bottom: 2px;

          .badge-val {
            padding: 5px 9.2px 5px 10px;
            border-radius: 15px;
            background-color: #e0e8f1;
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $protean-gray;
            margin-right: 12px;
          }
        }
      }
    }

    .photos {
      display: flex;
      flex-direction: row;

      img,
      .image-loader {
        //&:nth-of-type(5n+5) {
        //  margin-right: 0;
        //}
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
        object-fit: cover;
        width: 93px;
        height: 120px;
        margin: 16px 6px 0 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
      }
    }

    .load-more {
      display: flex;
      height: 32px;
      padding: 6px 20px;
      margin-top: 16px;
    }

    .rows {
      display: flex;
      flex-direction: column;

      .row-block {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        padding-right: 16px;

        .protean-badge {
          width: fit-content;
          white-space: nowrap;
          margin-right: 8px;
          height: 24px;
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $protean-gray;
          padding: 0 8px;
          display: flex;
          align-items: center;
        }

        .row-item {
          height: 30px;

          &.reports,
          &.GroupRelatedFiles {
            height: 36px;
          }

          display: flex;
          width: -webkit-fill-available;
          justify-content: space-between;
          align-items: center;
          border-radius: 4px;
          background-color: $protean-white;
          padding: 0 16px;

          .title {
            display: flex;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-black;
          }

          .actions {
            display: flex;

            .download-action {
              margin-right: 24px;

              svg {
                #download {
                  fill: $protean-dark-gray;
                }
              }
            }
          }
        }
      }
    }

    &.occurrences {
      padding: 24px 0 0 0;

      .block-title {
        display: none;
      }
    }

    .issue-details {
      width: 100%;
      display: flex;
      flex-direction: column;

      .header-details {
        width: 100%;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        background-color: $protean-white;
        margin-bottom: 12px;

        .header {
          display: flex;
          font-family: Roboto;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
          padding: 9px 24px;
          border-radius: 4px;
          flex-shrink: 2 !important;
        }
      }

      .content-details {
        display: flex;
        flex-direction: column;
        width: 100%;

        .main-details {
          width: 100%;
          display: flex;
          flex-direction: row;

          .details-cell {
            display: flex;
            font-family: Roboto;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-dark-gray;
            padding: 9px 24px;
            border-radius: 4px;
            word-break: break-word;
            align-items: center;

            .protean-badge {
              height: fit-content;
            }
          }
        }
      }
    }
  }
}

.p-24 {
  padding: 24px !important;
}

.no-data-block {
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Roboto;
  font-size: 24px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-dark-gray;
}

.alarm-options {
  width: 100%;

  .alarm-options-btn {
    height: 32px;
    margin-right: 8px;
  }

  .alarm-icon-btn {
    width: 32px;
    padding: 5px;
  }
}

.upload-file {
  display: flex;
  align-items: center;
  color: #fff;

  svg {
    #upload {
      fill: #fff;
    }
  }
}

.dot {
  display: flex;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background-color: #bfe85f;

  &-hide {
    display: none !important;
  }

  &-red {
    background-color: #dc6e6e;
  }

  &-yellow {
    background-color: #efc675;
  }
}

.h39 {
  height: 39px;
}

.switch {
  div {
    display: flex;
  }

  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-black;
  display: flex;
  flex-direction: row;
}

.nested-column {
  padding-left: 0 !important;
}

.equipment {
  .equipment-block-title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
  }

  .equipment-info-table {
    margin-top: 15px;

    .tank-label {
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      color: $protean-dark-gray;
      height: 24px;
      border-radius: 15px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      background-color: $protean-white;
      width: 100%;
      margin-right: 20px;
      margin-bottom: 8px;

      span {
        color: $protean-gray;
        margin-right: 4px;
      }
    }

    .col-4.pr-0.nested-column {
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10) {
        margin-top: 20px;
      }
    }
  }

  .equipment-info-block {
    margin-bottom: 25px;
  }

  .tank-label {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-dark-gray;
  }

  .alarm-block {
    margin-bottom: 32px;

    &.no-alarms {
      width: fit-content;
    }

    padding: 16px;
    border-radius: 4px;
    background-color: $protean-white;
    font-size: 13px;
    font-weight: bold;
    color: #3a4669;

    .alarm-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .alarm-item {
      margin-top: 3px;
      color: #3a4669;
      font-weight: normal;
    }

    .crc {
      margin-right: 16px;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 50%;

      div {
        border-radius: 50%;
        width: 10px;
        height: 10px;

        &.green {
          background-color: #bcd87a;
        }

        &.red {
          background-color: #d96161;
        }
      }
    }
  }
}

.reset-btn {
  background: none;
  border: none;
  padding: 0;
}

.atg-tab {
  .atg-tab-rectangle {
    text-align: center;
    background-color: #e0e8f1;
    font-family: Roboto;
    font-size: 13px;
    font-weight: bold;
    height: 32px;
    width: 90%;
    margin-bottom: 8px;
    padding-top: 8px;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.active {
      color: #3a4669;
      cursor: default;
    }

    &.inactive {
      color: $protean-gray;
      cursor: pointer;
    }

    &.inactive:hover {
      color: #3a4669;
    }
  }

  .atg-tab-triangle {
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-left: 16px solid #e0e8f1;
    border-bottom: 16px solid transparent;
  }
}

.fix-safari-checkbox {
  display: flex;
  width: 14px;
  height: 14px;
}

.color-black {
  color: $protean-black !important;
}

.remove-focus-default-btn:focus {
  outline: none;
  background-color: #578ef7;
  color: #ffffff;
}

.facility-tests {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
}

.connection-center-options {
  .site-icon {
    width: 32px;
    height: 32px;
    padding: 4px;
  }
}

.protean-notification-window.show {
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #eaeeef;
  background-color: #fff;
  padding: 0 16px 24px 24px;

  .dropdown-content-header {
    padding: 24px 24px 14px 24px;
    align-items: center;

    .notification-window-title {
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      color: $protean-black;
    }

    .setting {
      height: 32px;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3a4669;
    }

    .reset-btn {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #3f66d4;
    }
  }

  .dropdown-content-body.dropdown-scrollable {
    padding: 10px 16px 0 0;

    &::-webkit-scrollbar {
      width: 3px;
      height: 56px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3.5px;
      background-color: #f0f2f4;
    }

    .media-list {
      .media {
        .comment-indicator {
          margin-top: -4px;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          &.unread:hover {
            background-color: #eaeeef;

            .unread {
              background-color: transparent;
            }
          }

          .crc-indicator {
            width: 10px;
            height: 10px;
            background-color: #3f66d4;
            border: 1px #3f66d4 solid;
            border-radius: 50%;

            &.read {
              background-color: transparent;
            }
          }
        }

        .media-body {
          margin-left: 3px;

          .extra-info {
            .wo-label {
              margin-bottom: 8px;
              margin-top: 4px;
              width: fit-content;
              padding: 4px 8px 3px;
              border-radius: 4px;
              background-color: $protean-white;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-dark-gray;

              .wo-link {
                font-size: 13px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: #3f66d4;
                margin-left: 2px;
              }
            }

            .wo-site {
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-dark-gray;
            }
          }
        }
      }
    }
  }

  .dropdown-content-footer {
    margin-top: 32px;
    margin-right: 10px;
    padding: 0;
    background-color: transparent;
    border: none;

    .protean-btn {
      height: 32px;
      padding: 6px 20px;
    }

    .readBtn {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #3f66d4;
    }
  }
}

.fix-modal-report-facility {
  div {
    width: 100%;
    height: 100%;
  }

  .fix-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-border.protean-loader {
      height: 100px;
      width: 100px;
    }
  }
}

.disabled-icon {
  path {
    fill: lightgray;
  }
}

.mobile-modal {
  margin-top: auto;
  width: 100%;
  padding: 40px 31px 32px 32px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(87, 142, 247, 0.3);
  border: solid 1px #eaeeef;
  background-color: #fff;

  .mobile-text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: $protean-dark-gray;

    &.title {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      margin-bottom: 34px;
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .protean-btn {
      max-width: 400px;
      width: 100%;
    }
  }
}

.fix-dashboard-chart {
  max-height: 460px;
}

.diagnose-modal-content {
  .connection-setup {
    &.run-diagnose {
      .setup-body > .connection-row,
      .setup-header {
        .ip-port {
          width: 180px;
        }

        .command {
          width: 190px;
        }

        .version {
          width: 200px;
        }

        .text-status {
          width: 140px;
        }

        .site {
          width: -fill-available;
          margin-right: auto;
        }

        .status {
          display: flex;
          justify-content: center;
          width: 90px;
        }
      }
    }

    &.no-sites {
      .setup-header {
        .port {
          margin: 0 17px;
        }

        .site {
          display: none;
        }
      }

      .setup-body {
        max-height: 500px;
        overflow-y: auto;

        .connection-row {
          .port {
            margin-right: 8px;
            margin-left: 17px;
          }

          .site {
            flex-direction: row;
            align-items: center;

            .protean-btn {
              height: 28px;
              width: 28px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;

              &.remove-field {
                margin-right: 8px;

                .fix-color {
                  fill: $protean-dark-gray;
                }
              }
            }
          }

          .row-error {
            color: #ff0000;
            padding-left: 32px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    padding: 24px 27px 0 27px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    .setup-header {
      flex-direction: row;

      .header-cell {
        width: 180px;
        font-family: Roboto;
        color: $protean-gray;

        &.site {
          width: auto;
        }

        &.command {
          width: 280px;
        }

        &.port {
          width: 100px;
        }
      }
    }

    .setup-body {
      max-height: 500px;
      overflow-y: auto;

      .connection-row {
        .connection-cell {
          width: 180px;
          color: $protean-dark-gray;

          &.command {
            width: 280px;
          }

          &.site {
            width: auto;
          }

          &.port {
            width: 100px;
          }
        }
      }
    }
  }

  .connection-modal-footer {
    .modal-btn {
      min-width: 130px;
      margin: 0;
    }

    .download-btn {
      display: flex;

      svg {
        fill: white;
        margin-right: 4px;
      }
    }
  }
}

.calendar-tooltip-icon {
  width: 24px;
  height: 18px;
  margin: auto 0;
  display: flex;
  justify-content: center;
}

.cabinet-view-modal-content {
  .fix-spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-border.protean-loader {
      height: 50px;
      width: 50px;
    }
  }

  //width: 100%;
  //margin-top: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  //iframe {
  //  width: 100%;
  //}
}

.file-search-input {
  width: 235px;
}

.max-w-180 {
  max-width: 180px;
}

.min-w-180 {
  min-width: 180px;
}

.dispatch-modal {
  padding: 25px;
  font-family: Roboto;

  .dispatch-success {
    font-size: 15px;
    line-height: 1.47;
    color: $protean-black;
  }

  .dispatch-header {
    font-size: 20px;
    font-weight: 500;
    color: $protean-black;
    margin-bottom: 18px;
  }

  .dispatch-choice {
    .dispatch-subheader {
      font-size: 16px;
      color: $protean-black;

      span {
        font-size: 14px;
        font-weight: normal;
        color: $protean-dark-gray;
      }
    }

    .dispatch-list-items {
      max-height: 274px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 3px;
        height: 56px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #f0f2f4;
      }
    }

    .dispatch-line {
      margin-top: 8px;
      width: 100%;
      height: 5px;
      background-color: $protean-white;
    }

    .action-item-block {
      margin-bottom: 12px;

      .action-item-checkbox {
        padding-top: 2px;
      }
    }

    .dispatch-action-item-title {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #283049;
    }

    .dispatch-comment {
      align-items: baseline;

      .add-span {
        font-family: Roboto;
        font-size: 16px;
        color: $protean-black;
      }

      .tip-span {
        font-family: Roboto;
        font-size: 14px;
        color: $protean-dark-gray;
      }
    }

    .dispatch-comment-input {
      font-size: 14px;
      padding: 8px;
      width: 100%;
      border-radius: 4px;
      border: solid 1px #eaeeef;
      background-color: #fff;
    }
  }

  .dispatch-confirm {
    .dispatch-site-header {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.38;
      color: #283049;
    }

    .dispatch-report-header {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: #283049;
    }

    .dispatch-subheader {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #283049;
    }

    .attachment {
      font-size: 14px;
      color: $protean-black;
    }

    .email-line {
      display: flex;
      flex-direction: row;
      align-items: center;

      .key {
        font-size: 13px;
        font-weight: 500;
        color: $protean-gray;
      }

      .value {
        font-size: 14px;
        color: $protean-black;
      }

      .value-input {
        min-width: 80%;
        padding: 2px;

        &.error {
          border: 1px solid #f6a4a4;
          border-radius: 4px;
        }
      }
    }

    .check-icon {
      padding: 5px;
    }

    .photo-hint {
      font-size: 13px;
      font-weight: 500;
      color: $protean-gray;
    }

    .items-container {
      display: flex;
      border-left: 2px solid #7794e4;
      padding-left: 12px;

      &.photos {
        width: auto;

        .photo-block {
          margin-right: 10px;
          width: 164px;
          max-height: 225px;

          &:nth-of-type(4) {
            margin-right: 0;
          }
        }
      }
    }

    .issue-block {
      max-height: 450px;
      overflow-y: scroll;
    }
  }

  .modal-btn {
    min-width: 120px;
  }
}

.rotate-180 {
  rotate: 180deg;
}

#react-cool-portal {
  .magic-button-menu.atg-dropdown-menu.dropdown-menu {
    .dropdown-item {
      span {
        width: 100%;
      }
    }
  }

  .atg-dropdown-menu.dropdown-menu {
    width: fit-content !important;

    button {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
      border: none !important;
      background-color: $protean-white !important;

      &.dropdown-toggle:after {
        margin-left: auto;
      }

      &:hover {
        background-color: #f0f2f4 !important;
      }

      &.reportBtn {
        height: 28px;
        width: 28px;
        border-radius: 4px !important;
        background-color: $protean-white;
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;

        &.hw32 {
          height: 32px;
          width: 32px;
        }
      }

      &.dropdown-item {
        cursor: pointer;
        border: none !important;
        padding-left: 10px;
        padding-right: 10px;
        background-color: $protean-white;
        min-width: 198px !important;
        color: $protean-dark-gray;

        span {
          width: 86%;
          margin-left: auto;
        }

        &.disabled-item {
          cursor: default;
          color: $protean-dark-gray;
          opacity: 0.5;

          svg {
            g {
              path {
                fill: $protean-dark-gray;
              }
            }
          }
        }
      }

      &.dropdown-item:hover {
        background-color: #f0f2f4 !important;
        color: $protean-dark-gray;

        &.disabled-item {
          background-color: $protean-white !important;
          color: $protean-dark-gray;
          opacity: 0.5;
        }
      }
    }

    &.row-dot-actions-menu {
      width: 142px !important;
      min-width: 142px !important;

      button.dropdown-item {
        min-width: fit-content !important;
      }
    }
  }
}

#react-cool-portal {
  .atg-dropdown-menu.row-dot-actions-menu {
    button {
      color: $protean-dark-gray;

      &.dropdown-item {
        color: $protean-dark-gray;

        &.disabled-item {
          color: $protean-gray;
          opacity: 1;

          svg {
            g {
              path {
                fill: $protean-gray;
              }
            }
          }
        }
      }

      &.dropdown-item:hover {
        background-color: #f0f2f4 !important;
        color: $protean-dark-gray;

        &.disabled-item {
          background-color: $protean-white !important;
          color: $protean-gray;
          opacity: 1;
        }
      }
    }
  }
}

.buttonIconDropdown {
  &.review-download-dropdown {
    display: flex;
    position: relative;

    button {
      // min-width: 32px !important;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
      border: none !important;
      background-color: $protean-white !important;

      &.dropdown-toggle:after {
        margin-left: auto;
      }

      &:hover {
        background-color: #f0f2f4 !important;
      }

      &.reportBtn {
        height: 28px;
        width: 28px;
        border-radius: 4px !important;
        background-color: $protean-white;
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;

        &.hw32 {
          height: 32px;
          width: 32px;
        }
      }

      &.dropdown-item {
        border: none !important;
        padding-left: 10px;
        padding-right: 10px;
        background-color: $protean-white;
        min-width: 198px !important;
        color: $protean-dark-gray;
      }

      &.dropdown-item:hover {
        background-color: #f0f2f4 !important;
        color: $protean-dark-gray;
      }
    }

    .dropdown-menu {
      min-width: 200px !important;
      top: 100%;
      background-color: $protean-white !important;
      border-radius: 4px !important;
      border: none;
      margin-top: 0;
      max-height: 120px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
        height: 56px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #f0f2f4;
      }
    }
  }
}

.modal-report-switch {
  &.buttons {
    margin-top: 0;
  }

  display: flex;
  align-items: center;
  margin-left: 8px;

  .protean-btn {
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 156px;

    &:first-of-type {
      margin-right: 8px;
    }

    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
}

.mb-8 {
  margin-bottom: 8px;
}

.pr-150 {
  padding-right: 150px !important;
}

.batch-select {
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #578ef7;
}
.dashboard-widget .exportbtn-ddl {
  display: none !important;
}
