.protean-vendors {
  padding: 32px 32px 48px 33px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #fff;

  .title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    margin-bottom: 32px;

    .addBtn {
      padding: 0 16px;
      display: flex;
      align-items: center;

      &:focus {
        transition: color 150ms ease-in;

        svg {
          path {
            fill: #3a4669;
            transition: fill 300ms ease-in;
          }
        }
      }
    }
  }
}

.removeNewContact {
  height: 38px !important;
  width: 38px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-content {
  .header {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
  }

  div.col-12 {
    .contact-form {
      .edit-btns {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        .protean-btn-default {
          margin-left: 12px;
        }
      }

      width: 100%;

      .basic-info {
        display: flex;
        justify-content: space-between;

        .contact-field {
          width: 23%;
        }
      }

      .extra-info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-left: 30px;

        .checkbox-block {
          margin-left: -30px;
          display: flex;
          align-items: center;

          input {
            margin-right: 16px;
          }
        }

        .contact-field {
          margin-top: 16px;
        }
      }

      .contact-field {
        width: 33%;

        &.email_period {
          margin-right: auto;
          margin-left: 1%;
        }

        &.toggler-block {
          display: flex;
          width: 27%;
          align-items: center;
          padding-top: 20px;
          justify-content: space-between;

          .protean-toggler {
            margin-right: -17px;
          }

          .toggler-label {
          }
        }

        .label-field {
          color: #a4a8b1;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }

    .contacts-rows {
      display: flex;
      flex-wrap: wrap;
      padding: 0 10px;

      .col-custom-add-contact {
        display: flex;
        flex: 0 0 20%;

        /* &.name { */
        /*   flex: 0 0 12% */
        /* } */

        /* &.email { */
        /*   flex: 0 0 12% */
        /* } */

        &.phone {
          flex: 0 0 15%;
        }

        &.crcs {
          align-items: center;

          .crc {
            display: flex;
            height: 10px;
            width: 10px;
            background-color: #c4c7cf;
            border-radius: 50%;
            margin-right: 10px;

            &.green {
              background-color: #c3e27a;
            }
          }
        }

        &.send {
          display: flex;
          justify-content: space-between;
          flex: 0 0 25%;

          .protean-btn.removeContact {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            margin-top: -2px;
            margin-left: auto;
            cursor: pointer;
          }
        }
      }
    }

    .row {
      .col-3 {
        margin-bottom: 8px;

        &.action-col {
          display: flex;
          justify-content: space-between;

          .protean-btn.removeContact {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            margin-top: -2px;
            margin-left: auto;
            cursor: pointer;
          }
        }
      }

      .divider {
        margin-bottom: 16px;
      }
    }

    .add-contact-btn {
      width: auto !important;
      justify-content: center;
      align-content: center;

      &:not(:disabled) {
        svg {
          .fix-color {
            fill: #3a4669;
          }
        }
      }

      svg {
        margin-top: 2px;
      }
    }

    .add-contacts {
      margin-bottom: 8px;
      display: flex;
      flex-wrap: wrap;

      .col-field {
        width: 230px;
        margin-bottom: 8px;

        &.email {
          padding: 0;
          margin-left: 8px;

          input {
            width: 230px;
          }
        }

        &.phone {
          width: auto;
          display: flex;
          margin-left: 8px;

          input {
            width: 190px;
          }

          .buttons {
            display: flex;
            height: fit-content;
            margin-top: 3px;

            .protean-btn {
              margin-left: 8px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 34px;
            }
          }
        }

        &.name {
          input {
            width: 230px;
          }
        }

        &.contact-header-title {
          font-size: 13px;
          font-weight: 500;
          color: $protean-gray;
          width: 230px;

          &.phone {
            width: 200px;
          }

          &.email {
            margin-right: 8px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}

.assignment-body {
  .title {
    font-size: 15px;
    color: $protean-black;
  }

  div.col-12 {
    margin-bottom: 32px;

    .singleClient {
      font-size: 15px;
      color: $protean-black;

      span {
        margin-left: 8px;
        font-size: 15px;
        font-weight: 500;
        color: $protean-black;
      }
    }

    &.assignment-option {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      &.disabled {
        span,
        input {
          opacity: 0.5;
        }
      }

      span {
        font-size: 15px;
        color: $protean-black;
        margin-left: 16px;
        margin-right: 10px;
      }

      .sub-input {
        width: 100%;
        padding-top: 18px;
        padding-left: 30px;
      }
    }
  }
}
