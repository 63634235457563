.review-test-modal {
  min-width: 1172px;
  &.change-status {
    min-width: 652px;
    &.add-tests {
      .buttons-add-test {
        margin-top: 40px;
        display: flex;
        // warning
        justify-content: flex-end;
        .protean-btn {
          margin-top: 0;
          color: inherit;
          width: 120px;
          &.add-tests {
            background-color: #578ef7;
            color: #fff;
          }
          &.cancel {
            margin-right: 16px;
            background-color: $protean-white;
          }
        }
      }
    }
    .m-body {
      padding: 0 32px 32px 32px;
      flex-direction: column;
      .site-title {
        margin: 24px 0;
        span {
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          margin-right: 16px;
        }
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }
      .description-text {
        margin-top: 16px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-dark-gray;
        width: 100%;
        display: flex;
      }
      .field-title {
        margin-top: 16px;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
      }
      .form-control {
        resize: none;
      }
      .protean-btn {
        margin-top: 24px;
        color: #fff;
        &:disabled {
          opacity: 0.7;
        }
        &.fail {
          background-color: #d96161;
        }
        &.pass {
          background-color: #578ef7;
        }
      }
      .test-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .test-status-val {
          width: 60%;
          margin-top: 6px;
          .val-badge {
            border-radius: 12px;
            background-color: $protean-white;
            width: fit-content;
            padding: 5px 10px;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
            .crc {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              display: inline-flex;
              margin-right: 8px;
              &.overdue {
                background-color: #dc6e6e;
              }
              &.fail,
              &.failed {
                background-color: #dc6e6e;
              }
              &.upcoming {
                background-color: #efc675;
              }
              &.test {
                background-color: #efc675;
              }
              &.retest {
                background-color: #efc675;
              }
              &.pass {
                background-color: #bcd87a;
              }
              &.planned {
                background-color: #bcd87a;
              }
            }
          }
          &:nth-of-type(2) {
            margin-top: 12px;
            font-family: Roboto;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }
        }
        .test-name {
          margin-top: 6px;
          width: 40%;
          display: flex;
          height: 24px;
          align-content: center;
          span {
            display: flex;
            align-items: center;
          }
          &:nth-of-type(1) {
            margin-top: 12px;
            font-family: Roboto;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }
        }
      }
    }
  }
  &.upload {
    min-width: 649px;
    .m-header {
      padding: 32px;
    }
    .m-body {
      padding: 0 32px 32px 32px;
      .fields {
        width: 100%;
        .field-title {
          margin-bottom: 8px;
          margin-top: 20px;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
        }
        .protean-btn {
          svg {
            margin-top: -1px;
          }
          display: flex;
          justify-content: center;
          margin-top: 16px;
        }
      }
    }
  }
  .custom-close-btn {
    top: 16px;
  }
  .m-header {
    padding: 32px 32px 0 32px;
    h2 {
      margin-bottom: 0;
    }
  }
  .m-body {
    &.review-tests-body {
      max-height: 840px;
    }
    .big-Dot {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: #eaeeef;
      display: flex;
      justify-content: center;
      align-items: center;

      .sub-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.\31 {
          background-color: #dc6e6e;
        }

        &.\32 {
          background-color: #efc675;
        }

        &.\33 {
          background-color: #bcd87a;
        }
      }
    }
    .protean-status-badge {
      padding: 5px 10px;
      border-radius: 12px;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-gray;
      background-color: $protean-white;
      width: fit-content;
      .crc {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-flex;
        margin-right: 8px;
        &.overdue {
          background-color: #dc6e6e;
        }
        &.fail,
        &.failed {
          background-color: #dc6e6e;
        }
        &.upcoming {
          background-color: #efc675;
        }
        &.test {
          background-color: #efc675;
        }
        &.retest {
          background-color: #efc675;
        }
        &.pass {
          background-color: #bcd87a;
        }
        &.planned {
          background-color: #bcd87a;
        }
      }
    }
    width: 100%;
    display: flex;
    .info {
      flex: 0.5;
      padding: 7px;
      display: flex;
      flex-direction: column;
      .comments {
        // padding-left: 25px;
        width: 100%;
        // padding-bottom: 8px;
        .comments-title {
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          display: flex;
        }
        .comment-list {
          margin-top: -12px;
          height: 122px;
          overflow-y: auto;
          width: 100%;
          padding-left: 16px;
          padding-bottom: 16px;
          &::-webkit-scrollbar {
            width: 3px;
            height: 56px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 3.5px;
            background-color: #f0f2f4;
          }
          .singe-comment {
            margin-top: 12px;
            .comment-text {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-black;
            }
            .comment-author {
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              align-items: center;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-black;
              display: flex;
              justify-content: space-between;
              margin-bottom: 6px;
              overflow: hidden;
              .author {
                display: flex;
                width: auto;
                min-width: fit-content;
              }
              .test-badges {
                width: -webkit-fill-available;
                display: flex;
                flex-direction: row;
                height: 22px;
                flex-wrap: wrap;
                justify-content: flex-start;
                .test-badge {
                  padding: 4px 8px;
                  border-radius: 4px;
                  background-color: $protean-white;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $protean-gray;
                  max-width: 120px;
                  margin-right: 4px;
                  height: 22px;
                  white-space: pre;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  align-items: center;
                  display: block;
                  white-space: nowrap;

                  &:first-of-type {
                    margin-left: 8px;
                  }
                  .type-dot {
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    margin-right: 8px;
                    display: inline-flex;
                    transform: translateY(1px);

                    &.blue-dot {
                      background-color: #578ef7;
                    }
                    &.red-dot {
                      background-color: #dc6e6e;
                    }
                  }
                }
              }
              .comment-date {
                display: flex;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $protean-gray;
                width: auto;
                min-width: fit-content;
              }
            }
          }
        }
      }
      .info-block {
        display: flex;
        flex-wrap: wrap;
        .info-title {
          width: 20%;
          padding-left: 25px;
          font-family: Roboto;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          margin-bottom: 9px;
        }
        .info-value {
          width: 80%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
          margin-bottom: 9px;
        }
      }
      .test-collapse {
        width: 100%;
        margin-top: 16px;
        padding: 0px 16px;
        .test-collapse__trigger {
          width: 100%;
          height: 34px;
          display: block;
          padding: 8px 17px 8px 25px;
          border-radius: 4px;
          background-color: $protean-white;
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;
          margin-bottom: 16px;
          .collapsible-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .crc {
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background-color: #bcd87a;
              display: inline-flex;
              transform: translate(3px, -3px);
            }
          }
        }
        // .test-collapse__contentOuter {
        //   padding: 16px 8px;
        // }
      }
      .related-tests {
        font-family: Roboto;
        height: 466px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
          height: 56px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3.5px;
          background-color: #f0f2f4;
        }
        .related-title {
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-gray;
          display: flex;
          margin: 24px 0 16px 29px;
        }
        .related-table {
          .wo-thead {
            border-radius: 4px;
            background-color: $protean-white;
            height: 32px;
            .wo-tr {
              height: inherit;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-dark-gray;
            }
          }
          .wo-tbody {
            .wo-tr {
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-dark-gray;
              height: 40px;
              border-radius: 4px;
              background-color: $protean-white;
              margin: 4px 0;
              .expanded-icon {
                &.true {
                  transform: rotate(90deg);
                }
                &.left-margin {
                  margin-left: 16px;
                }
              }
            }
            .sub-row {
              display: flex;
              margin-top: 11px;
              width: 97%;
              margin-left: auto;
              .left,
              .right {
                display: flex;
                flex-wrap: wrap;
                width: 50%;
                .title {
                  width: 50%;
                  font-size: 13px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $protean-gray;
                  margin-bottom: 9px;
                }
                .value {
                  width: 50%;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $protean-black;
                  margin-bottom: 9px;
                }
              }
            }
          }
        }
      }
      .divider {
        width: 100%;
        height: 5px;
        background-color: $protean-white;
      }
      .comment-section {
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
        span {
          display: flex;
          margin-bottom: 8px;
        }
        .buttons {
          width: 100%;
          margin-top: 16px;
          display: flex;
          justify-content: flex-end;
          .protean-btn {
            margin-left: 8px;
            &:disabled {
              opacity: 0.7;
            }
            &.schedule {
              padding: 7px;
              svg {
                width: 26px;
                height: 26px;
              }
            }
            &.pass {
              color: #fff;
              width: 120px;
              background-color: #578ef7;
            }
            &.fail {
              width: 120px;
              color: #fff;
              background-color: #d96161;
            }
          }
        }
      }
    }
    .full-page-btn {
      position: absolute;
      top: 213px;
      right: 20px;
      padding: 0;
      z-index: 999;
      padding: 0;
    }
    .pdf-view {
      padding-right: 24px;
      padding-bottom: 7px;
      height: 840px;
      flex: 0.5;
      display: flex;
      flex-direction: column;
      &.fPage {
        width: 100%;
        flex: 1;
        height: 845px;
        padding: 0;
      }
      iframe {
        width: 100%;
        height: 100%;
        margin-top: 16px;
      }
    }
  }
}

.protean-test {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  font-family: Roboto;
  .tests-breadcrumbs {
    border-bottom: 5px solid $protean-white;
    padding: 9px 8px 0 8px;
    display: flex;
    justify-content: space-between;
    .breadcrumb {
      display: flex;
      flex: 0.9 1;
      height: 31px;
      margin: 0 4px;
      cursor: pointer;
      align-content: center;
      justify-content: center;
      border-radius: 4px;
      background-color: $protean-white;
      font-size: 12px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
      align-items: center;
      text-align: center;
      &:hover {
        background-color: #f0f2f4;
      }
    }
  }
  .divider {
    height: 5px;
    width: 100%;
    background-color: $protean-white;
  }
  .main {
    padding: 17px;

    .test-title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
    }

    .test-header {
      padding: 16px;
      display: flex;
      flex-direction: row;
      //justify-content: space-between;

      h1 {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
      }

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-dark-gray;
      }

      .add-test {
        .protean-btn {
          display: flex;
          align-items: center;
          .upload-icon {
            g {
              path {
                fill: #3a4669;
              }
            }
          }
        }
        .input {
          .input-title {
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }
        }
      }
    }

    .fetch-filters {
      .clear-button {
        position: absolute;
        right: -32px;
        top: 20px;
        height: 38px;
        width: 38px;

        svg {
          height: 23px;
          width: 23px;
        }
      }

      .fetch-filter > span {
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
      }

      &.false {
        .Collapsible {
          .Collapsible__contentOuter {
            overflow: hidden;
          }
        }
      }

      &.true {
        .Collapsible {
          .Collapsible__contentOuter {
            overflow: visible !important;
          }
        }
      }
    }

    .test-table {
      //margin-top: 32px;
      width: 100%;
      padding: 0 17px;
      //display: flex;
      .extra {
        height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        justify-content: flex-end;
        .protean-btn {
          &:disabled {
            opacity: 0.7;
            color: unset;
          }
          &.pass,
          &.schedule {
            color: #fff;
            width: 120px;
            background-color: #578ef7;
          }
          &.fail {
            width: 120px;
            color: #fff;
            background-color: #d96161;
          }
          &.upload {
            display: flex;
            align-items: center;
            svg {
              g {
                path {
                  fill: #3a4669;
                }
              }
            }
          }
        }
        .table-title {
          font-family: Roboto;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
        }
      }

      .main-table {
        border-spacing: 0;
        width: 100%;

        .protean-pagintaion {
          margin: 16px 0;
          display: flex;
          justify-content: flex-end;
        }

        .wo-thead {
          .wo-tr {
            .wo-th {
              font-size: 13px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-dark-gray;
              position: relative;
            }

            .date-filter {
              display: none;

              &.date {
                display: block;

                .picker {
                  position: absolute;
                  top: 5px;
                  left: 7px;
                  display: none;
                  z-index: 9;

                  .close-picker {
                    display: none;

                    &.show {
                      display: block;
                      position: absolute;
                      top: 5px;
                      right: 10px;
                    }
                  }

                  &.true {
                    display: block;

                    .datepicker-block {
                      .icon {
                        display: none;
                      }

                      input {
                        width: 98%;
                        height: 26px;
                        padding-left: 6px;
                        // width: inherit;
                        // height: inherit;
                      }

                      .custom-picker {
                        top: 25px;
                      }
                    }
                  }

                  .sortNone {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 28px;
                  }

                  .sortUp {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 28px;
                    margin-bottom: 0 !important;
                  }

                  .sortDown {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 28px;
                    margin-top: 0 !important;
                  }
                }
              }
            }

            .filter-select.date {
              display: none;
            }

            .hide {
              display: none;
            }
          }
        }

        .wo-tr {
          border-radius: 4px;
          background-color: $protean-white;
          align-items: center;
          user-select: none;
          margin-bottom: 2px;
        }

        .wo-tbody {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-dark-gray;

          .no-records {
            height: 150px;
            display: flex;
            align-items: center;
          }
        }

        .wo-th {
          height: 32px;
          padding-left: 18px;
        }

        .sub-table {
          margin-top: 7px;
          margin-bottom: 24px;

          .wo-th {
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
          }

          .wo-tbody {
            .wo-tr {
              background-color: transparent;
              &.new {
                background-color: $protean-white;
              }
            }
          }

          .wo-td {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-black;

            .actions {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              width: 100%;

              .protean-btn {
                height: 32px;
                &:disabled {
                  opacity: 0.7;
                }
              }
              .review-download-dropdown {
                display: flex;
                position: relative;
                button {
                  min-width: 32px !important;
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #fff;
                  border: none !important;
                  background-color: $protean-white !important;
                  &.dropdown-toggle:after {
                    margin-left: auto;
                  }
                  &:hover {
                    background-color: #f0f2f4 !important;
                  }
                  &.reportBtn {
                    height: 32px;
                    width: 32px;
                    border-radius: 4px !important;
                    background-color: $protean-white;
                    border: none;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                  }
                  &.dropdown-item {
                    border: none !important;
                    padding-left: 10px;
                    padding-right: 0;
                    background-color: $protean-white;
                    min-width: 198px !important;
                    color: $protean-dark-gray;
                  }
                  &.dropdown-item:hover {
                    background-color: #f0f2f4 !important;
                    color: $protean-dark-gray;
                  }
                }
                .dropdown-menu {
                  min-width: 200px !important;
                  top: 100%;
                  background-color: $protean-white !important;
                  border-radius: 4px !important;
                  border: none;
                  margin-top: 0;
                  max-height: 100px;
                  overflow-y: scroll;
                  &::-webkit-scrollbar {
                    width: 3px;
                    height: 56px;
                  }
                  &::-webkit-scrollbar-thumb {
                    border-radius: 3.5px;
                    background-color: #f0f2f4;
                  }
                }
              }
            }

            .cell {
              font-family: Roboto;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $protean-gray;
              border-radius: 15px;
              background-color: $protean-white;
              padding: 5px 9px;
              padding: 5px 9px;

              .color-status {
                display: none;
              }

              &.pending {
                display: flex;
                align-items: center;

                .color-status {
                  border-radius: 50%;
                  display: inline-flex;
                  margin-right: 5px;
                  width: 10px;
                  height: 10px;
                  background-color: #efc675;
                }
              }
            }
          }
        }

        .wo-td {
          height: 48px;
          position: relative;
          padding-left: 18px;
          align-items: center;

          .protean-status-badge {
            padding: 5px 10px;
            border-radius: 12px;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
            background-color: $protean-white;

            .crc {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              display: inline-flex;
              margin-right: 8px;

              &.overdue {
                background-color: #dc6e6e;
              }

              &.fail,
              &.failed {
                background-color: #dc6e6e;
              }

              &.upcoming {
                background-color: #efc675;
              }

              &.test {
                background-color: #efc675;
              }

              &.retest {
                background-color: #efc675;
              }

              &.pass {
                background-color: #bcd87a;
              }

              &.planned {
                background-color: #bcd87a;
              }
            }
          }

          .wo-badge {
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $protean-gray;
            padding: 5px 9px;
            border-radius: 15px;
            background-color: #eaeeef;
          }

          .expanded-icon {
            &.true {
              transform: rotate(90deg);
            }
          }

          .big-Dot {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: #eaeeef;
            display: flex;
            justify-content: center;
            align-items: center;

            .sub-dot {
              width: 10px;
              height: 10px;
              border-radius: 50%;

              &.\31 {
                background-color: #dc6e6e;
              }

              &.\32 {
                background-color: #efc675;
              }

              &.\33 {
                background-color: #bcd87a;
              }
            }
          }
        }
      }
    }
  }
}

.zi-9 {
  z-index: 9999 !important;
}
.zi-8 {
  z-index: 95;
}

.custom-picker {
  z-index: 999;
  padding: 32px;
  padding-top: 34px;
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  width: 377px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  &.show {
    display: block;
  }
  .showButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    .protean-btn {
      width: 120px;
      margin-left: 16px;
    }
  }
  .datepicker-week-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  .datepicker-months {
    display: flex;
    justify-content: space-between;
    .datepicker-block {
      width: 311px;
      .datepicker-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 25px;
        .datepicker-nav-button {
          display: flex;
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          &.hide-nav {
            visibility: hidden;
          }
        }
        .datepicker-month-title {
          align-self: center;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $protean-black;
        }
      }
      .datepicker-week-day {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: $protean-dark-gray;
        width: 44.4px;
      }
      .datepicker-month-picker {
        width: 60px;
        height: 32px;
      }
      .datepicker-days-block {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .datepicker-day {
          background: none;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: normal;
          text-align: center;
          color: $protean-black;
          width: 44.4px;
          height: 32px;
          &.datepicker-month-picker {
            width: 77px;
            height: 50px;
            &.selected:hover {
              background-color: #bcd87a;
            }
            &:hover {
              background-color: $protean-white;
            }
          }
          &.selected {
            border-radius: 4px;
            background-color: #bcd87a;
            &.range {
              background-color: #bcd87a;
            }
          }
          &.range {
            background-color: $protean-white;
          }
          &.in-list {
            background-color: $protean-white;
          }
          &:disabled,
          &[disabled] {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }
  }
  &.range {
    width: 720px;
    &.single {
      width: auto;
    }
  }
}

.custom-pdf-modal.reopen.schedule_date {
  height: 240px;
}

.add-test-modal {
  padding: 32px 32px 32px 32px !important;
  .custom-close-btn {
    top: 25px;
  }
  .divider {
    height: 3px;
    width: 100%;
    background-color: $protean-white;
    margin: 24px 0;
  }
  .col-6,
  .col-12 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    .input-main-title {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      .tooltip-icon {
        margin-top: 1px;
      }
    }
    .input-main-description {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-dark-gray;
    }
    .input-title {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-gray;
    }
    .invisible {
      max-height: 58px;
    }
    .disable-click {
      pointer-events: none;
      max-height: 58px;
      #tests-next-date {
        position: absolute;
        right: 26px;
        bottom: 10px;
      }
    }
    .protean-btn,
    .protean-btn-default {
      width: 122px;
    }
  }
}

.test-comment-input {
  top: 12px !important;
  textarea {
    padding: 8px !important;
  }
}

.test-reply-input {
  margin-left: 2px !important;
  margin-right: 4px !important;
  textarea {
    padding: 8px !important;
  }
}

.protean-test-details {
  padding: 17px;

  .collapsible-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    color: $protean-black;
    .crc {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #bcd87a;
      display: inline-flex;
      transform: translate(5px, -8px);
    }
  }

  .report-table {
    min-height: 350px;
  }

  .report-spinner {
    min-height: 55px !important;
  }

  .report-dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.1875rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    min-width: 11.25rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.8125rem;
    color: #333;

    &.show {
      display: block;
    }
  }

  .test-card-header {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    margin-bottom: 24px;
  }

  .details-subheader {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-black;
    margin-bottom: 12px;
  }

  .item-header {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    color: $protean-dark-gray;

    height: 30px;
    width: 20%;
    align-items: center;
    border-radius: 4px;
    background-color: $protean-white;
    padding-left: 16px;
    margin-right: 2px;
  }
  .item-value {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    color: $protean-black;

    padding-left: 16px;
    width: 80%;
    border-radius: 4px;
    background-color: $protean-white;
    align-items: center;
    margin-right: 30px;
  }

  .btn-with-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      g {
        path {
          fill: #3a4669;
        }
      }
    }
  }

  .pass {
    color: #fff;
    width: 120px;
    background-color: #578ef7;
  }

  .comment-btn {
    color: #ffffff;
    float: right;
    background-color: #578ef7;
  }

  .fail {
    color: #fff;
    width: 120px;
    background-color: #d96161;
  }

  .protean-status-badge {
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-gray;
    background-color: $protean-white;

    .crc {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-flex;
      margin-right: 8px;

      &.overdue {
        background-color: #dc6e6e;
      }

      &.fail,
      &.failed {
        background-color: #dc6e6e;
      }

      &.upcoming {
        background-color: #efc675;
      }

      &.test {
        background-color: #efc675;
      }

      &.retest {
        background-color: #efc675;
      }

      &.pass {
        background-color: #bcd87a;
      }

      &.planned {
        background-color: #bcd87a;
      }
    }
  }

  .comment {
    margin-bottom: 19px;
    &.sub {
      margin-left: 32px;
      margin-top: 19px;
      margin-bottom: 0;
    }
    .comment-user {
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      margin-bottom: 4px;
      .comment-type {
        padding: 4px 12px;
        border-radius: 4px;
        background-color: $protean-white;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-gray;
        display: inline-flex;
        align-items: center;
        margin-left: 20px;
        .type-dot {
          border-radius: 50%;
          width: 10px;
          height: 10px;
          margin-right: 8px;

          &.blue-dot {
            background-color: #578ef7;
          }
          &.red-dot {
            background-color: #dc6e6e;
          }
        }
      }
      .comment-issue {
        padding: 7px 12px;
        border-radius: 4px;
        background-color: $protean-white;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
        margin-right: 8px;
      }
    }
    .comment-text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-black;
      margin-bottom: 8px;
      line-height: 20px;
      .mention-str {
        padding: 5px 12px;
        border-radius: 15px;
        background-color: $protean-white;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #3f66d4;
      }
    }
    .comment-file {
      display: flex;
      flex-direction: column;
      button {
        width: fit-content;
        appearance: none;
        border: none;
        padding: 2px 4px;
        border-radius: 4px;
        background-color: $protean-white;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $protean-black;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
      }
    }
    .comment-date {
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $protean-gray;
      .reply-btn {
        font-size: 13px;
        font-weight: 500;
        color: $protean-gray;
        margin-left: 16px;
        &:hover {
          color: $protean-black;
        }
      }
    }
    .reply-comment {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      .comment-mention {
        width: 55%;
        .comment-mention__suggestions {
          background-color: $protean-white !important;
          max-height: 80px !important;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 3px;
            height: 56px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 3.5px;
            background-color: #f0f2f4;
          }
          ul {
            max-height: 80px !important;
          }
          padding: 6px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
          .comment-mention__suggestions__list {
            .comment-mention__suggestions__item {
              padding: 5px 10px;
              &.comment-mention__suggestions__item--focused {
                background-color: #eaeeef !important;
              }
            }
          }
        }
        .comment-mention__input {
          border-radius: 4px;
          border: solid 1px #eaeeef;
          background-color: #ffffff;
          padding-left: 16px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $protean-black;
          height: 38px;
        }
      }
      .protean-default-input {
        width: 55%;
      }
      .protean-btn {
        width: 120px;
        &:nth-of-type(1) {
          margin: 0 2px;
        }
      }
    }
  }
}

.dropdown-readonly {
  .dropdown-readonly-btn {
    border: none;
    padding: 4px 7px 4px 8px;
    border-radius: 4px;
    background-color: $protean-white;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 22px;

    &.active {
      svg {
        transform: rotate(0);
      }
    }
    svg {
      width: 10px;
      height: 10px;
      margin-left: 3px;
      transform: rotate(270deg);
    }
  }
}

.dropdown-readonly-menu {
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: $protean-white;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $protean-gray;
  display: none;
  position: absolute;
  &.show {
    display: flex;
    z-index: 9999;
  }
  flex-direction: column;
  .dropdown-readonly-item {
    padding: 4px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $protean-gray;
  }
}
